import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import LabResultModal from './LabResultModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import {
    componentKey as PatientLabResultComponentKey,
    setIsLabResultModalOpen,
    setIsNewLabResultAdd,
    setLabResultId,
    setLabResultData,
    setIsOpenDeleteLabResultPopup,
    setSelectedRowData,
} from '../Lab/PatientLabSlice';
import {
    componentKey as PatientDashboardComponentKey
} from '../PatientDashboardSlice';
import { deletePatientLabResult, getAllLabsDetails, getAllPatientLabResultDetails } from '../Lab/PatientLabSaga';
import moment from 'moment';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';
import General from '../../../../libs/utility/General';
import useBase64Image from '../../../../containers/SettingsTab/MyAccountCard/Tabs/PrintConfiguration/useS3Image';
import { USER_PERMISSIONS } from '../../../../libs/UserPermissionsConstants';
import ShowForAllowedPermissionsOnly from '../../../../containers/Wrappers/ShowForAllowedPermissionsOnly';
import { useShowForAllowedPermissionsOnly } from '../../../../libs/customHooks/useShowForAllowedPermissionsOnly';

function LabResult() {

    const showForAllowPermission = useShowForAllowedPermissionsOnly()


    const dispatch = useDispatch();
    const { isLabResultModalOpen, allLabResultData, isOpenDeleteLabResultPopup } = useSelector((state) => state[PatientLabResultComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);
    const [rowData, setRowData] = useState(null)
    const getBase64 = useBase64Image();


    const handleLabResultModal = () => {
        dispatch(setIsNewLabResultAdd(true));
        dispatch(setLabResultId(''));
        dispatch(setLabResultData({}));
        dispatch(setIsLabResultModalOpen(true));
    }

    const handleEditClick = (labresult) => {
        dispatch(setIsNewLabResultAdd(false));
        dispatch(setLabResultId(labresult.uuid));
        dispatch(setIsLabResultModalOpen(true));
        dispatch(setLabResultData(labresult));
    };

    const handleDeleteClick = (labresultId) => {
        dispatch(deletePatientLabResult({ patientId: currentPatientId, labresultId }));
    };

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientLabResultDetails({ patientId: currentPatientId, page: 1, limit: 10 }));
    }, [currentPatientId]);

    useEffect(() => {
        dispatch(getAllLabsDetails());
    }, [])

    const handleView = async (attachment) => {
        const base64 = await getBase64(attachment)
        if (base64) {
            General.openBase64FileInNewTab(base64);
        }
    }


    const coloumns = [
        // {
        //     field: "appointment",
        //     label: <Label>Appointment</Label>,
        //     renderLogic: (row) => {
        //         return <>
        //             <div className='flex items-center'>
        //                 <div>
        //                     <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.appointment ?? '-'}</Heading>
        //                     <Label color='gray-500'>{row.subLabel}</Label>
        //                 </div>
        //             </div>
        //         </>
        //     },
        // },

        {
            field: "referenceID",
            label: <Label fontWeight="font-bold">Lab Reference ID</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientLabTest?.map((test) => {
                            return <div key={test.uuid} className='flex items-center'>
                                <div>
                                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{`#${test?.uuid?.substring(test.uuid.length - 6)}`}</Heading>
                                </div>
                            </div>
                        })
                    }
                </>
            },
        },

        {
            field: "test",
            label: <Label fontWeight="font-bold">Test</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientLabTest?.map((test) => {
                            return <div key={test.uuid} className='flex items-center'>
                                <div>
                                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{test?.name}</Heading>
                                </div>
                            </div>
                        })
                    }
                </>
            },
        },
        {
            field: "labCenter",
            label: <Label fontWeight="font-bold">Lab Center</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.labs?.name}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "reviewer",
            label: <Label fontWeight="font-bold">Reviewer</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.reviewer}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        // {
        //     field: "result",
        //     label: <Label>Result</Label>,
        //     renderLogic: (row) => {
        //         return <>
        //             <div className='flex items-center'>
        //                 <div>
        //                     <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.result}</Heading>
        //                 </div>
        //             </div>
        //         </>
        //     },
        // },
        {
            field: "attachment",
            label: <Label fontWeight="font-bold">Attachment</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientLabTest?.map((test, index) => {
                            return (
                                <div key={test.uuid} className='flex items-center'>
                                    <div>
                                        <Heading type={HEADING.H1} color='met-primary' fontSize={"md"} customClasses="cursor-pointer" onClickCb={() => { handleView(test.path) }}>
                                            {test.path ? `Attachment ${index + 1}` : "-"}
                                        </Heading>
                                    </div>
                                </div>
                            );
                        })
                    }

                </>
            },
        },


        {
            field: "receivedDate",
            label: <Label fontWeight="font-bold">Received Date</Label>,
            renderLogic: (row) => {
                return <>
                    {
                        row.patientLabTest?.map((test) => {
                            return <div key={test.uuid} className='flex items-center'>
                                <div>
                                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{test.reportDate ? moment(test.reportDate).format('DD-MM-YYYY') : ''}</Heading>
                                </div>
                            </div>
                        })
                    }
                </>
            },
        },

        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer" onClickCb={() => setRowData(row)}>{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[
                            ...(showForAllowPermission(USER_PERMISSIONS.DELETE_LAB_REPORT) ? [{
                                label: "Delete", value: null, onChangeCb: () => {
                                    dispatch(setIsOpenDeleteLabResultPopup(true))
                                    setRowData(row)
                                }
                            }] : []),

                            {
                                label: "Edit Lab Result", value: null, onChangeCb: () => {
                                    handleEditClick(row)
                                    dispatch(setSelectedRowData(row))
                                }
                            }]}
                            selectCb={(option) => {
                                option?.onChangeCb();
                            }} />
                    </div>

                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                    <ShowForAllowedPermissionsOnly name={USER_PERMISSIONS.UPLOAD_LAB_REPORT}>
                        <Button onClickCb={handleLabResultModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add New Lab Result</Button>
                    </ShowForAllowedPermissionsOnly>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allLabResultData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isLabResultModalOpen ? <LabResultModal open={isLabResultModalOpen} close={() => dispatch(setIsLabResultModalOpen(false))} /> : null}
            {isOpenDeleteLabResultPopup ? <DeletePopup
                open={isOpenDeleteLabResultPopup}
                okButton="Delete"
                cancelButton='Cancel'
                confirmationMessage="Delete"
                onClickCancel={() => { dispatch(setIsOpenDeleteLabResultPopup(false)) }}
                onClickOk={() => handleDeleteClick(rowData.uuid)}
            /> : null}
        </>
    )
}

export default LabResult