import React, { useEffect, useRef, useState } from 'react';
import Button from '../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Icons from '../../../../components/icons/Icons';
import { insuranceData } from './InsurancePrefrance/InsuranceData';
import PatientInsuranceDetails from './InsurancePrefrance/PatientInsuranceDetails';
import AddPatientInsurance from '../../../../containers/AddPatient/Insurance/AddPatientInsurance';
import ModalComponent from '../../../../components/common-components/modal/ModalComponent';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setIsOpenAddInsuranceModal } from '../../AddPatient/AddPatientSlice';
import { componentKey as dashboardComponentKey } from '../PatientDashboardSlice';

export const InsuranceType = {
	PRIMARY_INSURANCE: 'Primary',
	SECONDARY_INSUREANCE: 'Secondary',
};
const Insurance = ({ patientId }) => {
	const { isOpenAddInsuranceModal } = useSelector((state) => state[componentKey]);

	const formRef = useRef();
	const dispatch = useDispatch();

	return (
		<>
			<div className="flex items-center justify-between p-2 px-4">
				<div className="text-xl font-bold">Insurance</div>
				<div>
					<Button
						onClickCb={() => dispatch(setIsOpenAddInsuranceModal(true))}
						startIcon={{ icon: <Icons iconName="plusVectorIcon" color="#1B5984" /> }}
						variant={BUTTON_VARIANTS.OUTLINED}
					>
						Add Insurance
					</Button>
				</div>
			</div>
			<div className="h-[calc(100vh-300px)] overflow-y-auto met-scrollbar">
				<PatientInsuranceDetails patientId={patientId} />
			</div>
			{/* //TODO: add patient insurance on dashboard */}
			{/* {isInsuranceModalOpen ? */}
			{isOpenAddInsuranceModal ? (
				<ModalComponent
					title="Add Insurance Details"
					customClasses="w-[80%]"
					open={isOpenAddInsuranceModal}
					close={() => {
						dispatch(setIsOpenAddInsuranceModal(false));
					}}
					footerButton={
						<Button
							onClickCb={() => {
								formRef.current.submitForm();
							}}
							type="submit"
						>
							Save
						</Button>
					}
				>
					<AddPatientInsurance isAddInsurance={true} patientId={patientId} ref={formRef} />
				</ModalComponent>
			) : null}

			{/* hiding this card as using the reusable component which is on patient onboarding */}
			{/* {isInsuranceModalOpen ? <InsuranceMoodal isOpen={isInsuranceModalOpen} onCloseCb={() => setIsInsuranceModalOpen(false)} /> : null} */}
		</>
	);
};

export default Insurance;
