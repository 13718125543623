import React, { useEffect, useState } from 'react'
import CardContainer from '../../../components/common-components/Cards/Container/CardContainer'
import UncheckNotification from './ UncheckNotification'
import ProgressView from './ProgressView'
import FaceSheetCard from '../../Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import NavigationCapsuleTabs from '../../../components/common-components/roundedtoggles/NavigationCapsuleTabs'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey, setIsOpenCustomDatePickerModal } from '../DashboardSlice'
import UpcommingAppointment from './UpcommingAppointment'
import useGarbageCollector from '../../../libs/garbagecollector/garbageCollectorHooks'
import ModalComponent from '../../../components/common-components/modal/ModalComponent'
import DatePicker from '../../../components/common-components/datePicker'
import Button from '../../../components/common-components/button/Button'
import { getUpcommingAppointments } from '../DashboardSaga'
import { componentKey as routerComponentKey } from '../../../routes/RoutesSlice'
import SuperBill from '../../Billing/SuperBill/SuperBill'

function ProviderDashboardWrapper() {
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedLabel, setSelectedLabel] = useState("");
    const [formDate, setFormDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    const { isOpenCustomDatePickerModal, birthDays } = useSelector(state => state[componentKey])
    const { loggedInUser } = useSelector(state => state[routerComponentKey])
    useGarbageCollector(componentKey)
    const dispatch = useDispatch()
    const role = localStorage.getItem('role')

    const PIECHART_LABELS = {
        LAST_MONTHS: "Last Months",
        THIS_YEAR: "This Year",
        CUSTOM: "Custom",
    }

    useEffect(() => {
        const params = {
            dateFrom: formDate,
            dateTo: toDate,
            providerId: loggedInUser?.uuid
        }
        dispatch(getUpcommingAppointments({ params }))
    }, [dispatch, formDate, toDate, loggedInUser?.uuid])

    const handleChange = (label) => {
        setSelectedTab(tabs.findIndex(tab => tab.label === label))
        const endDate = new Date()
        switch (label) {
            case PIECHART_LABELS.LAST_MONTHS:
                setFormDate(moment(endDate).subtract(1, 'month').toDate())
                setToDate(endDate)
                break;

            case PIECHART_LABELS.THIS_YEAR:
                setFormDate(moment(endDate).subtract(1, 'year').toDate())
                setToDate(endDate)
                break;

            case PIECHART_LABELS.CUSTOM:
                dispatch(setIsOpenCustomDatePickerModal(true))
                break;

            default:
                break;
        }
        setSelectedLabel(label);
    };


    const tabs = [
        { label: PIECHART_LABELS.LAST_MONTHS },
        { label: PIECHART_LABELS.THIS_YEAR, },
        { label: PIECHART_LABELS.CUSTOM }
    ];


    const compareBirthday = (dob) => {

        let birthDate = moment(dob).date()
        let birthMonth = moment(dob).month()
        let currentDate = moment().date()
        let currentmonth = moment().month()

        if (currentDate === birthDate && currentmonth === birthMonth) {
            return "Today"
        }

        else if (birthDate === currentDate + 1 && currentmonth === birthMonth) {
            return "Tomorrow"
        }
        else {
            return "Comming Soon..."
        }

    }

    return (
        <>
            <div>
                <CardContainer >
                    <div className='font-bold text-gray-800 fs-18 mb-2'>Some Important Notices Needs Your Attention
                    </div>
                    <UncheckNotification />
                </CardContainer>
            </div>
                <div className={`${role !== 'Biller' ? 'flex flex-1' : ''} justify-between w-full flex-wrap-reverse`}>
                <div className='flex-3 min-w-[768px] '> {role === 'Biller' ? <SuperBill isDontShowOnTab={true} />  : <UpcommingAppointment />  } </div>
                <div className='flex flex-wrap flex-1'>
                     <div className='min-w-[395px] max-w-[480px] w-full'>
                        {role !== 'Biller' && <CardContainer>
                            <div className='flex justify-center'>
                                <NavigationCapsuleTabs
                                    tabs={tabs}
                                    name="piechartTabs"
                                    onChangeCb={handleChange}
                                    value={selectedTab}
                                    outerBorderClass='rounded-md'
                                    buttonClass='rounded-md'
                                    btnBorderColor="border-gray-500"
                                />
                            </div>
                            <FaceSheetCard customClassName="mt-3 min-h-[290px]" paddingTopClass={0}>
                                <ProgressView formDate={formDate} toDate={toDate} selectedLabel={selectedLabel} />
                            </FaceSheetCard>
                        </CardContainer>}
                    </div>
                    {/* <div className='min-w-[395px] max-w-[480px] w-full'>
                        <CardContainer customCssClass='min-h-[275px]'>
                            <div className='font-bold fs-18 ml-4'>Birthdays</div>
                            <div className='h-[250px] overflow-y-auto met-scrollbar '>
                                <hr className='mt-[8px] shadow-md' />
                                {birthDays?.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <div className='flex items-center column-gap-10px p-2 w-full'>
                                            <div>
                                                <Avatar
                                                    customClass='rounded-full'
                                                    width={48}
                                                    height={48}
                                                    name={`${item.firstname} ${item.lastname}`}
                                                    url={item.profilepicture}
                                                ></Avatar>
                                            </div>
                                            <div className='flex-col flex-1'>
                                                <div className='flex justify-between items-center w-full'>
                                                    <div className='font-bold text-met-primary'>{`${item.firstname} ${item.lastname}`}</div>
                                                    <div className='mt-[px]  font-bold text-gray-500 '>  {compareBirthday(item.dob)}  </div>
                                                </div>

                                                <div className='flex justify-between'>
                                                    <div className='mt-[5px] font-bold text-gray-500'>
                                                        {General.getformattedDate(item.dob)}
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <hr className='mt-[8px] shadow-md' />
                                    </React.Fragment>
                                ))}
                            </div>
                        </CardContainer>
                    </div> */}


                </div >
            </div >
            {selectedLabel === PIECHART_LABELS.CUSTOM ?
                <ModalComponent showIcon={false} customBodyClasses='min-w-[400px]' open={isOpenCustomDatePickerModal} close={() => { dispatch(setIsOpenCustomDatePickerModal(false)) }}
                    footerButton={<div className='mt-3'>
                        <Button onClickCb={() => {
                            setFormDate(startDate)
                            setToDate(endDate)
                        }}>Apply</Button> </div>}>
                    <div className='flex column-gap-20px'>
                        <div>
                            <DatePicker label='Date From' placeholder='Choose Date' value={startDate} onChangeCb={
                                (date) => {
                                    setStartDate(date)
                                    if (!endDate || date > endDate) {
                                        setEndDate(date);
                                    } else if (date < endDate) {
                                        setEndDate(null);
                                    }
                                }
                            } />
                        </div>
                        <div> <DatePicker
                            label='Date End'
                            placeholder='Choose Date'
                            value={endDate}
                            onChangeCb={(date) => {
                                setEndDate(date)
                            }}
                            minDate={startDate || new Date()}
                        /></div>
                    </div>

                </ModalComponent> : null
            }
        </>
    )
}

export default ProviderDashboardWrapper