import { VALIDATION_REGEX } from '../../../../../libs/constant';

export const profileLabelNames = {
	PROVIDER_TYPE: 'Provider Type',
	SELECT_SPECIALITY: 'Select Speciality',
	ROLE: 'Role',
	FIRST_NAME: 'First Name',
	LAST_NAME: 'Last Name',
	GENDER: 'Gender',
	DATE_OF_BIRTH: 'Date Of Birth',
	NMC_ID: 'NMC ID',
	PROVIDER_PHONE_NUMBER: 'Phone Number',
	OFFICE_FAX_NUMBER: 'Office Fax Number',
	EMAIL: 'Email',
	LANGUAGES_SPOKEN: 'Languages Spoken',
	YEARS_OF_EXPRIENCE: 'Year Of Experience',
	WORK_LOCATIONS: 'Work Locations',
	DEPARTMENT: 'Department',
	ADDRESS_1: 'Address 1',
	ADDRESS_2: 'Address 2',
	CITY: 'City',
	STATE: 'State',
	COUNTRY: 'Country',
	ZIP_CODE: 'Zip Code',
	LICENSED_STATE: 'Licensed State (s)',
	LICENCE_NUMBER: 'Licence Number',
	LICENCE_EXPIRY_DATE: 'Licence Expiry Date',
};

export const profileFieldNames = {
	PROVIDER_TYPE: 'providerType',
	SELECT_SPECIALITY: 'speciality',
	ROLE: 'role',
	FIRST_NAME: 'firstName',
	LAST_NAME: 'lastName',
	GENDER: 'gender',
	DATE_OF_BIRTH: 'dob',
	NMC_ID: 'nmcId',
	PROVIDER_PHONE_NUMBER: 'phoneNumber',
	OFFICE_FAX_NUMBER: 'officeFaxNumber',
	EMAIL: 'email',
	LANGUAGES_SPOKEN: 'languagesSpoken',
	YEARS_OF_EXPRIENCE: 'yearsOfExperience',
	WORK_LOCATIONS: 'locationIds',
	DEPARTMENT: 'departmentIds',
	ADDRESS_1: 'addressLine1',
	ADDRESS_2: 'addressLine2',
	CITY: 'city',
	STATE: 'state',
	COUNTRY: 'country',
	ZIP_CODE: 'zip',
	LICENSED_STATE: 'licensedStates',
	LICENCE_NUMBER: 'licenceNumber',
	LICENCE_EXPIRY_DATE: 'licenceExpireDate',
	BIO: "bio",
	EXPERTIESIN: 'expertiesIn',
	EDUCATIONWORKEXPRIENCE: 'educationWorkExperience',
	PROVIDER_PROFILE_IMAGE: 'profilePicture',
	PROVIDER_IMAGE: 'image'
};

export const profileFieldsArray = (isProviderOrAdmin = false) => [
	...(isProviderOrAdmin ? [{
		fieldName: "providerType",
		fieldLabel: profileLabelNames.PROVIDER_TYPE,
		isRequired: false,
		placeholder: profileLabelNames.PROVIDER_TYPE,
		type: 'dropdown',
		isDropdown: true,
	}] : []),
	...(isProviderOrAdmin ? [{
		fieldName: profileFieldNames.SELECT_SPECIALITY,
		fieldLabel: profileLabelNames.SELECT_SPECIALITY,
		isMultiSelect: true,
		regexPattern: '',
		placeholder: profileLabelNames.SELECT_SPECIALITY,
		type: 'dropdown',
	}] : []),
	{
		fieldName: profileFieldNames.ROLE,
		fieldLabel: profileLabelNames.ROLE,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.ROLE,
		type: 'dropdown',
		isDropdown: true
	},
	{
		fieldName: profileFieldNames.FIRST_NAME,
		fieldLabel: profileLabelNames.FIRST_NAME,
		isRequired: false,
		regexPattern: VALIDATION_REGEX.NAME_REGEX,
		placeholder: profileLabelNames.FIRST_NAME,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.LAST_NAME,
		fieldLabel: profileLabelNames.LAST_NAME,
		isRequired: false,
		regexPattern: VALIDATION_REGEX.NAME_REGEX,
		placeholder: profileLabelNames.LAST_NAME,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.GENDER,
		fieldLabel: profileLabelNames.GENDER,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.GENDER,
		type: 'dropdown',
		isDropdown: true
	},
	{
		fieldName: profileFieldNames.DATE_OF_BIRTH,
		fieldLabel: profileLabelNames.DATE_OF_BIRTH,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.DATE_OF_BIRTH,
		type: 'date',
	},
	...(isProviderOrAdmin ? [{
		fieldName: profileFieldNames.NMC_ID,
		fieldLabel: profileLabelNames.NMC_ID,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.NMC_ID,
		type: 'text',
	}] : []),
	{
		fieldName: profileFieldNames.PROVIDER_PHONE_NUMBER,
		fieldLabel: profileLabelNames.PROVIDER_PHONE_NUMBER,
		isRequired: false,
		regexPattern: VALIDATION_REGEX.MOBILE_NUM_REGEX,
		placeholder: profileLabelNames.PROVIDER_PHONE_NUMBER,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.OFFICE_FAX_NUMBER,
		fieldLabel: profileLabelNames.OFFICE_FAX_NUMBER,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.OFFICE_FAX_NUMBER,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.EMAIL,
		fieldLabel: profileLabelNames.EMAIL,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.EMAIL,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.LANGUAGES_SPOKEN,
		fieldLabel: profileLabelNames.LANGUAGES_SPOKEN,
		isMultiSelect: true,
		regexPattern: '',
		placeholder: profileLabelNames.LANGUAGES_SPOKEN,
		type: 'dropdown',
	},
	{
		fieldName: profileFieldNames.YEARS_OF_EXPRIENCE,
		fieldLabel: profileLabelNames.YEARS_OF_EXPRIENCE,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.YEARS_OF_EXPRIENCE,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.WORK_LOCATIONS,
		fieldLabel: profileLabelNames.WORK_LOCATIONS,
		isMultiSelect: true,
		regexPattern: '',
		placeholder: profileLabelNames.WORK_LOCATIONS,
		type: 'dropdown',
	},
	{
		fieldName: profileFieldNames.DEPARTMENT,
		fieldLabel: profileLabelNames.DEPARTMENT,
		isRequired: false,
		isMultiSelect: true,
		regexPattern: '',
		placeholder: profileLabelNames.DEPARTMENT,
		type: 'dropdown',
	},
	{
		fieldName: profileFieldNames.ADDRESS_1,
		fieldLabel: profileLabelNames.ADDRESS_1,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.ADDRESS_1,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.ADDRESS_2,
		fieldLabel: profileLabelNames.ADDRESS_2,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.ADDRESS_2,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.CITY,
		fieldLabel: profileLabelNames.CITY,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.CITY,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.STATE,
		fieldLabel: profileLabelNames.STATE,
		isDropdown: true,
		regexPattern: '',
		placeholder: profileLabelNames.STATE,
		type: 'dropdown',
	},
	{
		fieldName: profileFieldNames.COUNTRY,
		fieldLabel: profileLabelNames.COUNTRY,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.COUNTRY,
		type: 'text',
	},
	{
		fieldName: profileFieldNames.ZIP_CODE,
		fieldLabel: profileLabelNames.ZIP_CODE,
		isRequired: false,
		regexPattern: VALIDATION_REGEX.ZIP_CODE_REGEX,
		placeholder: profileLabelNames.ZIP_CODE,
		type: 'text',
	},
	...(isProviderOrAdmin ? [{
		fieldName: profileFieldNames.LICENSED_STATE,
		fieldLabel: profileLabelNames.LICENSED_STATE,
		isMultiSelect: true,
		regexPattern: '',
		placeholder: profileLabelNames.LICENSED_STATE,
		type: 'dropdown',
	}] : []),
	...(isProviderOrAdmin ? [{
		fieldName: profileFieldNames.LICENCE_NUMBER,
		fieldLabel: profileLabelNames.LICENCE_NUMBER,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.LICENCE_NUMBER,
		type: 'text',
	}] : []),
	...(isProviderOrAdmin ? [{
		fieldName: profileFieldNames.LICENCE_EXPIRY_DATE,
		fieldLabel: profileLabelNames.LICENCE_EXPIRY_DATE,
		isRequired: false,
		regexPattern: '',
		placeholder: profileLabelNames.LICENCE_EXPIRY_DATE,
		type: 'date',
	}] : []),
];

