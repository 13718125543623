import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../components/common-components/selectDropdown';
import Icons from '../../components/icons/Icons';
import DatePicker from '../../components/common-components/datePicker';
import Button from '../../components/common-components/button/Button';
import { MET_COLORS } from '../../libs/constant';
import { BUTTON_VARIANTS } from '../../components/common-components/button/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSuperBill, getPatientReports, getPatientsPaymentDetails } from './ReportsSaga';
import General from '../../libs/utility/General';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
	CSV_FILE_HEADERS_FOR_SUPER_BILLS,
	CSV_HEADERS_FOR_PATIENT_PAYMENTS,
	CVS_HEADERS,
	bindData,
	patientPaymentData,
	superbillData,
} from '../Patients/PatientsGridList/Constant';
import { componentKey } from './ReportsSlice';
import Input from '../../components/common-components/input/Input';

function CsvReports() {
	const [selectedReport, setSelectedReport] = useState({});
	const [patientReport, setSpatientReport] = useState({
		status: '',
		dateFrom: '',
		dateTo: '',
	});
	const [superBillReport, setSuperBillReport] = useState({
		status: '',
		date: '',
		patient: {},
	});
	const [patientPaymentReports, setPatientPaymentReports] = useState({
		date: '',
		patient: {},
		options: '',
	});
	const [showDownload, setShowDownload] = useState(false);
	const [showDownloadButtonForSuperBill, setShowDownloadButtonForSuperBill] = useState(false);
	const [showDownloadButtonForPatientPaymentReports, setShowDownloadButtonForPatientPaymentReports] = useState(false);
	const [showDateFromFilter, setShowDateFromFilter] = useState(false);
	const [showDateToFilter, setShowDateToFilter] = useState(false);

	const dispatch = useDispatch();
	const { patientReportsState, superBillData, patientPayments } = useSelector((state) => state[componentKey]);

	useEffect(() => {
		switch (selectedReport.value) {
			case 'patientsReport':
				setShowDateFromFilter(true);
				setShowDateToFilter(true);
				break;
			default:
				setShowDateFromFilter(false);
				setShowDateToFilter(false);
		}
	}, [selectedReport]);

	useEffect(() => {
		setSpatientReport(false)
		setShowDownload(false);
		setShowDownloadButtonForSuperBill(false);
		setShowDownloadButtonForPatientPaymentReports(false);
	}, [selectedReport]);

	useEffect(() => {
		if (selectedReport.value === 'patientsReport') {
			if (patientReport?.dateFrom !== '' && patientReport?.dateTo !== '' && patientReport?.status !== '') {
				dispatch(getPatientReports({ paginationState: patientReport }));
				setShowDownload(true);
				setShowDownloadButtonForSuperBill(false);
				setShowDownloadButtonForPatientPaymentReports(false);
			}
		}
	}, [patientReport]);

	useEffect(() => {
		if (selectedReport.value === 'superBillReport') {
			if (superBillReport.patient !== '' || superBillReport.status !== '' || superBillReport.date !== '') {
				const params = {
					patientId: (superBillReport?.patient?.value || '').toLowerCase(),
					status: superBillReport.status,
					date: superBillReport.date,
				};
				dispatch(getAllSuperBill(params));
				setShowDownloadButtonForSuperBill(true);
				setShowDownloadButtonForPatientPaymentReports(false);
				setShowDownload(false);
			}
		}
	}, [dispatch, superBillReport.patient, superBillReport.status, superBillReport.date]);

	useEffect(() => {
		if (selectedReport.value === 'patientPaymentReport') {
			if (
				patientPaymentReports.patient !== '' ||
				patientPaymentReports.options !== '' ||
				patientPaymentReports.date !== ''
			) {
				const params = {
					patientId: (patientPaymentReports?.patient?.value || '').toLowerCase(),
					options: patientPaymentReports.options,
					date: patientPaymentReports.date,
				};
				dispatch(getPatientsPaymentDetails(params));
				setShowDownloadButtonForPatientPaymentReports(true);
				setShowDownload(false);
				setShowDownloadButtonForSuperBill(false);
			}
		}
	}, [dispatch, patientPaymentReports.patient, patientPaymentReports.options, patientPaymentReports.date]);

    return (
        <div className="flex flex-col">
            <div className="flex">
                <div className="flex column-gap-10px items-center">
                    <div className="flex-1 min-w-410 p-2">
                        <SelectDropdown
                            label="Select Report"
                            placeholder="Select Report"
                            options={[
                                { label: "Patient's Report", value: 'patientsReport' },
                                { label: 'SuperBill Report', value: 'superBillReport' },
                                { label: 'Patient Payment Report', value: 'patientPaymentReport' },
                            ]}
                            onChangeCb={(val) => {
                                setSelectedReport(val);
                            }}
                        />
                    </div>
                </div>
                <div className="flex items-center column-gap-20px">
                    {showDateFromFilter && (
                        <div className="flex-1 min-w-410 max-w-440 ">
                            <DatePicker
                                label="From Date"
                                placeholder="Choose Date"
                                maxDate={new Date()}
                                onChangeCb={(date) => {
                                    setSpatientReport((prevState) => ({
                                        ...prevState,
                                        dateFrom: moment(date)?.format('YYYY-MM-DD'),
                                    }));
                                }}
                                value={patientReport?.dateFrom}
                            />
                        </div>
                    )}
                    {showDateToFilter && (
                        <div className="flex-1 min-w-410 max-w-440 ">
                            <DatePicker
                                label="To Date"
                                placeholder="Choose Date"
                                minDate={patientReport.dateFrom ? patientReport.dateFrom : new Date()}
                                maxDate={new Date()}
                                onChangeCb={(date) => {
                                    // setSpatientReport({dateTo:date});
                                    setSpatientReport((prevState) => ({
                                        ...prevState,
                                        dateTo: moment(date)?.format('YYYY-MM-DD'),
                                    }));
                                }}
                                value={patientReport?.dateTo}
                            />
                        </div>
                    )}
                    {selectedReport.value === 'patientsReport' && (
                        <div className="flex items-center column-gap-20px">
                            <div className="flex-1 min-w-410 max-w-440 ">
                                <SelectDropdown
                                    label="Search By Status"
                                    placeholder="Select Status"
                                    options={[
                                        { label: 'All', value: 'All' },
                                        { label: 'Active', value: 'true' },
                                        { label: 'InActive', value: 'false' },
                                    ]}
                                    onChangeCb={(val) => {
                                        setSpatientReport((prevState) => ({
                                            ...prevState,
                                            status: val.value,
                                        }));
                                    }}
                                    value={patientReport?.status}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {selectedReport.value === 'superBillReport' && (
                    <div className="flex items-center column-gap-20px">
                        <div className="flex-1 min-w-410 max-w-440 ">
                            <SelectDropdown
                                label="Search By Status"
                                placeholder="Select Status"
                                options={[
                                    { label: 'All', value: 'All' },
                                    { label: 'Paid', value: 'Paid' },
                                    { label: 'Unpaid', value: 'Unpaid' },
                                    { label: 'Draft', value: 'Draft' },
                                ]}
                                onChangeCb={(val) => {
                                    setSuperBillReport((prevState) => ({
                                        ...prevState,
                                        status: val.value,
                                    }));
                                }}
                                value={superBillReport?.status}
                            />
                        </div>
                        <div className="flex-1 min-w-410 max-w-440 ">
                            <DatePicker
                                label="Date Of Service"
                                placeholder="Choose Date"
                                maxDate={new Date()}
                                onChangeCb={(date) => {
                                    // setSpatientReport({dateTo:date});
                                    setSuperBillReport((prevState) => ({
                                        ...prevState,
                                        date: date,
                                    }));
                                }}
                                value={superBillReport?.date}
                            />
                        </div>
                        <div className="flex-1 min-w-410 max-w-440">
                            {/* <Input
								// endIcon={<Icons iconName={'searchIcon'} />}
								placeholder="Patient"
								label="Search Patient"
								onChangeCb={(e) => {
									setSuperBillReport((prevState) => ({
										...prevState,
										patient: e.target.value,
									}));
								}}
								value={superBillReport?.patient}
							/> */}

                            <SelectDropdown
                                // name={FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT}
                                value={superBillReport?.patient?.label}
                                onChangeCb={(e) => {
                                    setSuperBillReport((prevState) => ({
                                        ...prevState,
                                        patient: { label: e.label, value: e.uuid },
                                    }));
                                    // setFieldValue(
                                    // 	FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT,
                                    // 	General.getLabelValue(e.value)
                                    // );
                                    // return dispatch(setSchedulingAppointmentsPaginationState({patientId: e.value }));
                                }}
                                toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
                                placeholder="Search Patient"
                                label="Search Patient"
                                isAsync={true}
                                url="patients"
                                extractKeyFromResponse="patients"
                                labelKey={'firstName'}
                                valueKey={'uuid'}
                                labelKey2={'lastName'}
                            />
                        </div>
                    </div>
                )}
                {selectedReport.value === 'patientPaymentReport' && (
                    <div className="flex items-center column-gap-20px">
                        <div className="flex-1 min-w-410 max-w-440 ">
                            <Input
                                // endIcon={<Icons iconName={'searchIcon'} />}
                                placeholder="Receipt #"
                                label="Search Options"
                                onChangeCb={(e) => {
                                    setPatientPaymentReports((prevState) => ({
                                        ...prevState,
                                        options: e.target.value,
                                    }));
                                }}
                                value={patientPaymentReports?.options}
                            />
                        </div>
                        <div className="flex-1 min-w-410 max-w-440 ">
                            <DatePicker
                                label="Search by date"
                                placeholder="Choose Date"
                                maxDate={new Date()}
                                onChangeCb={(date) => {
                                    // setSpatientReport({dateTo:date});
                                    setPatientPaymentReports((prevState) => ({
                                        ...prevState,
                                        date: date,
                                    }));
                                }}
                                value={patientPaymentReports?.date}
                            />
                        </div>
                        <div className="flex-1 min-w-410 max-w-440">
                            {/* <Input
								// endIcon={<Icons iconName={'searchIcon'} />}
								placeholder="Patient"
								label="Search Patient"
								onChangeCb={(e) => {
									setPatientPaymentReports((prevState) => ({
										...prevState,
										patient: e.target.value,
									}));
								}}
								value={patientPaymentReports?.patient}
							/> */}

<SelectDropdown
								// name={FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT}
								value={patientPaymentReports?.patient?.label}
								onChangeCb={(e) => {
									setPatientPaymentReports({
										patient: {label: e.label, value: e.uuid},
									});
									// setFieldValue(
									// 	FORM_FIELDS_FOR_INSTANT_BOOKING.PATIENT,
									// 	General.getLabelValue(e.value)
									// );
									// return dispatch(setSchedulingAppointmentsPaginationState({ patientId: e.value }));
								}}
								toggleIcon={<Icons iconName={'searchIcon'}></Icons>}
								placeholder="Search Patient"
								label="Search Patient"
								isAsync={true}
								url="patients"
								extractKeyFromResponse="patients"
								labelKey={'firstName'}
								valueKey={'uuid'}
								labelKey2={'lastName'}
							/>
						</div>
					</div>
				)}
			</div>
			{showDownload && !showDownloadButtonForPatientPaymentReports && !showDownloadButtonForSuperBill && (
				<div className="flex items-center mt-5">
					<CSVLink data={bindData(patientReportsState)} headers={CVS_HEADERS}>
						<Button
							variant={BUTTON_VARIANTS.OUTLINED}
							startIcon={{ icon: 'downloadIcon', color: MET_COLORS.PRIMARY }}
							customBtnClass="text-met-primary"
						>
							Download
						</Button>
					</CSVLink>
				</div>
			)}
			{showDownloadButtonForSuperBill && !showDownloadButtonForPatientPaymentReports && !showDownload && (
				<div className="flex items-center mt-5">
					<CSVLink data={superbillData(superBillData)} headers={CSV_FILE_HEADERS_FOR_SUPER_BILLS}>
						<Button
							variant={BUTTON_VARIANTS.OUTLINED}
							startIcon={{ icon: 'downloadIcon', color: MET_COLORS.PRIMARY }}
							customBtnClass="text-met-primary"
						>
							Download
						</Button>
					</CSVLink>
				</div>
			)}
			{showDownloadButtonForPatientPaymentReports && !showDownloadButtonForSuperBill && !showDownload && (
				<div className="flex items-center mt-5">
					<CSVLink data={patientPaymentData(patientPayments)} headers={CSV_HEADERS_FOR_PATIENT_PAYMENTS}>
						<Button
							variant={BUTTON_VARIANTS.OUTLINED}
							startIcon={{ icon: 'downloadIcon', color: MET_COLORS.PRIMARY }}
							customBtnClass="text-met-primary"
						>
							Download
						</Button>
					</CSVLink>
				</div>
			)}
		</div>
	);
}

export default CsvReports;
