import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import VitalsModal from './VitalsModal'
import { MET_COLORS } from '../../../../libs/constant'
import {
    componentKey as PatientVitalsComponentKey,
    setIsVitalsModalOpen
} from '../Vitals/PatientVitalsSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { getAllPatientVitalsDetails } from '../Vitals/PatientVitalsSaga';
import General from '../../../../libs/utility/General';

function Vitals() {

    const dispatch = useDispatch();
    const { allVitalsData, isVitalsModalOpen } = useSelector((state) => state[PatientVitalsComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);

    const handleVitalsModal = () => {
        dispatch(setIsVitalsModalOpen(true));
    }

    const handleVitalsModalClose = () => {
        setIsVitalsModalOpen(false)
    }

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientVitalsDetails({ patientId: currentPatientId, page: 1, limit: 10 }));
    }, [currentPatientId]);

    const coloumns = [
        {
            field: "dates",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Dates</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                const formattedDate = General.getformattedDate(row.date)
                return <>
                    <div className='flex flex-col items-center'>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{formattedDate}</Heading>
                        <Label color='gray-500'>{row.time}</Label>
                    </div>
                </>
            },
        },

        {
            field: "temprature",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Temperature</Label>
                    <Label>{'('}&deg;f{`)`}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.temperature ? row.temperature : '-'}</Heading>
                </>
            },
        },

        {
            field: "bloodPressure",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Blood Pressure</Label>
                    <Label>{'(mmHg)'}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.systolic ? `${row.systolic}/${row.daistolic}` : '-'}</Heading>
                    </div>
                </>
            },
        },
        {
            field: "heartRate",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Heart Rate</Label>
                    <Label>{'(bpm)'}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.heartRate ? row.heartRate : '-'}</Heading>
                    </div>
                </>
            },
        },

        {
            field: "respirationRate",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Respiration Rate</Label>
                    <Label>{'(bpm)'}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.respiratoryRate ? row.respiratoryRate : '-'}</Heading>
                    </div>
                </>
            },
        },
        {
            field: "oxygenSaturation",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Oxygen Saturation</Label>
                    <Label>{'(%)'}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.oxygenSaturation ? row.oxygenSaturation : '-'}</Heading>
                    </div>
                </>
            },
        },

        {
            field: "height",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Height</Label>
                    <Label>{'(ft/in)'}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.height ? row.height : '-'}</Heading>
                    </div>
                </>
            },
        },

        {
            field: "weight",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Weight</Label>
                    <Label>{'(Kg)'}</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.weight ? row.weight : '-'}</Heading>
                    </div>
                </>
            },
        },
        {
            field: "bmi",
            label: <div className='flex-col justify-center items-center'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>BMI</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.BMI ? row.BMI : '-'}</Heading>
                    </div>
                </>
            },
        },
        {
            field: "note",
            label: <div className='flex-col justify-center items-center w-[100px]'>
                <div className='flex flex-col justify-center items-center'>
                    <Label fontWeight='bold'>Note</Label>
                </div>
            </div>,
            renderLogic: (row) => {
                return <>
                    <div className='w-[100px]'>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer text-center">{row.note ? row.note : '-'}</Heading>
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-end p-2 px-4'>
                <div className='flex'>
                    <Button onClickCb={handleVitalsModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add Vitals</Button>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allVitalsData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {<VitalsModal open={isVitalsModalOpen} close={() => dispatch(setIsVitalsModalOpen(false))} />}
        </>
    )
}

export default Vitals