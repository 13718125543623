import React, { useState, forwardRef, useEffect } from 'react';

import Label from '../../../components/common-components/label/Label';
import './styles.scss';
import Input from '../../../components/common-components/input/Input';
import Upload from '../../../components/common-components/upload/Upload';
import SelectDropdown from '../../../components/common-components/selectDropdown';
import DatePicker from '../../../components/common-components/datePicker';
import Icons from '../../../components/icons/Icons';
import { MET_COLORS, VALIDATION_REGEX } from '../../../libs/constant';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../libs/formsUtils';
import { FORM_FIELDS_NAMES, LANGUAGE_OPTIONS, TIMEZONE_OPTIONS } from './Constants';
import Checkbox from '../../../components/common-components/checkbox/Checkbox';
import GuardianDetailsModal from './GuardianDetailsModal';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setCreatePatientData, setEditDemographicsData, setIsFormSubmitted, setOpenValidateOtpModal } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import General from '../../../libs/utility/General';
import { getPatientDetails, updatePatientDetails, validateAadhaar } from '../../../pages/Patients/AddPatient/AddPatientSaga';
import { useParams } from 'react-router-dom';
import store from '../../../store/store';
import ImportFromAadhaar from './ImportFromAadhaar';
import ValidateOTPModal from './ValidateOTPModal';

const PatientDetails = forwardRef(function PatientDetails({ isUpdate = false, onUpdateCb = null }, formRef) {
	const { patientId } = useParams();
	const [isGuardianDetailsModalOpen, setIsGuardianDetailsModalOpen] = useState(false)
	const [isImportUsingAadhaarModalOpen, setIsImportUsingAadhaarModalOpen] = useState(false)
	const { isAadharProvided, fetchedPatientDetails, isFormSubmitted, createPatientData,openValidateOtpModal, createPatientFromAadhaar } = useSelector((state) => state[componentKey]);
	const { newlyAddedPatientId } = useSelector((state) => state[componentKey])

	useEffect(() => {
		return () => {
			if (isUpdate) {
				store.reducerManager.remove(componentKey);
			}
		};
	}, []);

	const fields = [
		{ fieldName: FORM_FIELDS_NAMES.FIRSTNAME, isRequired: true, isMinLength: true, isMaxLength: 20, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.MIDDLENAME,isRequired: false, isMinLength: false, isMaxLength: 20, regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.GENDER, isDropdown: true },
		{ fieldName: FORM_FIELDS_NAMES.LASTNAME, isRequired: true, isMinLength: true, isMaxLength: 20,	regexPattern: VALIDATION_REGEX.NAME_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.DOB, isRequired: true },
		{ fieldName: FORM_FIELDS_NAMES.AADHAR_NUMBER, isRequired: isAadharProvided, regexPattern: VALIDATION_REGEX.AADHAR_REGEX },
		{ fieldName: FORM_FIELDS_NAMES.MARITAL_STATUS, isDropdown: true },
	];
	const validationSchema = getValidationSchema(fields)
	const dispatch = useDispatch()

	useEffect(() => {
		if (patientId && patientId !== ":id" && isUpdate) {
			dispatch(getPatientDetails({ patientId }))
		}
	}, [dispatch, patientId, isUpdate])

	const initialValues = {
		[FORM_FIELDS_NAMES.PROFILE_PICTURE]: "",
		[FORM_FIELDS_NAMES.FIRSTNAME]: fetchedPatientDetails?.firstName || createPatientData?.firstName || "",
		[FORM_FIELDS_NAMES.MIDDLENAME]: fetchedPatientDetails?.middleName || createPatientData?.middleName,
		[FORM_FIELDS_NAMES.LASTNAME]: fetchedPatientDetails?.lastName || createPatientData?.lastName,
		[FORM_FIELDS_NAMES.DOB]: fetchedPatientDetails?.dob || createPatientData?.dob,
		[FORM_FIELDS_NAMES.GENDER]: { label: fetchedPatientDetails?.gender || createPatientData?.gender, value: fetchedPatientDetails?.gender || createPatientData?.gender },
		[FORM_FIELDS_NAMES.MARITAL_STATUS]: { label: fetchedPatientDetails?.maritalStatus || createPatientData?.maritalStatus, value: fetchedPatientDetails?.maritalStatus || createPatientData?.maritalStatus },
		[FORM_FIELDS_NAMES.AADHAR_NUMBER]: General.formateAadhar((fetchedPatientDetails?.aadhar || createPatientData?.aadhar || '').toString()),
		[FORM_FIELDS_NAMES.TIMEZONE]: { label: fetchedPatientDetails?.timeZone || createPatientData?.timeZone, value: fetchedPatientDetails?.timeZone || createPatientData?.timeZone },
		[FORM_FIELDS_NAMES.LANGUAGE]: { label: fetchedPatientDetails?.language || createPatientData?.language, value: fetchedPatientDetails?.language || createPatientData?.language },
		[FORM_FIELDS_NAMES.IS_PATIENT_AADHAR]: true,
	}

	return (
		<>
		{patientId === ":id" ? <div className="flex p-2 ">
			<Checkbox
				checked={createPatientFromAadhaar}
				onChangeCb={() => {
					setIsImportUsingAadhaarModalOpen(true);
				}}
				/>
			<div className="pl-2 text-gray-500 ">
				<Label fontWeight="semibold" color={"met-primary"}>Import Using Aadhar</Label>
			</div>
		</div> : null}
			<Formik
				innerRef={formRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => {
					if (onUpdateCb) {
						return onUpdateCb(values)
					}
					if (patientId && patientId === ":id") {
						values.gender = values.gender?.value;
						values.maritalStatus = values.maritalStatus?.value;
						values[FORM_FIELDS_NAMES.TIMEZONE] = values[FORM_FIELDS_NAMES.TIMEZONE]?.value;
						values[FORM_FIELDS_NAMES.LANGUAGE] = values[[FORM_FIELDS_NAMES.LANGUAGE]]?.value;
						if (isAadharProvided) {
							const adharNum = values[FORM_FIELDS_NAMES.AADHAR_NUMBER]?.replace(/-/g, '')
							dispatch(setCreatePatientData({ ...values, [FORM_FIELDS_NAMES.AADHAR_NUMBER]: Number(adharNum) }))
						} else {
							dispatch(setCreatePatientData({ ...values, [FORM_FIELDS_NAMES.IS_PATIENT_AADHAR]: false }))
						}
						dispatch(setIsFormSubmitted({ patientDetails: false }))
					} else {
						//TODO: this is for demographics if it causes problems find another way to handle this
						values.gender = values.gender?.value;
						values.maritalStatus = values.maritalStatus?.value;
						values[FORM_FIELDS_NAMES.TIMEZONE] = values[FORM_FIELDS_NAMES.TIMEZONE]?.value;
						values[FORM_FIELDS_NAMES.LANGUAGE] = values[[FORM_FIELDS_NAMES.LANGUAGE]]?.value;
						if (isAadharProvided) {
							const adharNum = values[FORM_FIELDS_NAMES.AADHAR_NUMBER]?.replace(/-/g, '');
							dispatch(setEditDemographicsData({ patient: { ...values, [FORM_FIELDS_NAMES.AADHAR_NUMBER]: Number(adharNum) } }))
						} else {
							dispatch(setEditDemographicsData({ ...values, [FORM_FIELDS_NAMES.IS_PATIENT_AADHAR]: false }))						

						}
					}
					// else{
					// 	dispatch(updatePatientDetails({ patientId: newlyAddedPatientId, payload: { ...createPatientData, ...values } }))
					// }
				}}
			>
				{({ submitForm,	isSubmitting, handleReset, values,handleSubmit, setFieldValue, handleBlur, ...props }) => {
					return (<Form onSubmit={handleSubmit} >
							<div className="custom-patient-details bg-opacity-3 rounded flex p-5 justify-between">
								<div>
									<div className="mb-3">
										<Label fontWeight="bold">Profile Picture</Label>
									</div>
									<Upload
										subTitle="Upload"
										customSubtitleClass="text-met-primary"
										customClass="w-[157px] h-[157px]"
										icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
										name={FORM_FIELDS_NAMES.PROFILE_PICTURE}
										value={values[FORM_FIELDS_NAMES.PROFILE_PICTURE] || ''}
										url={fetchedPatientDetails[FORM_FIELDS_NAMES.PROFILE_PICTURE] || ''}
										onChangeCb={async (file) => {
											const mimeType = file?.type || '';
											const base64String = await General.fileToBase64(file);
											const base64 = `data:${mimeType};base64,${base64String}`;
											setFieldValue(FORM_FIELDS_NAMES.PROFILE_PICTURE, base64);
										}}
										disabled={isFormSubmitted.patientDetails}
									/>
								</div>
								<div className="patient-details-wrapper justify-center flex column-gap-20px row-gap-15">
									<div className="Patient-details-container">
										<Input
											placeholder="Enter name"
											label="First Name"
											isRequired="true"
											asteriskPosition="end"
											name={FORM_FIELDS_NAMES.FIRSTNAME}
											value={values[FORM_FIELDS_NAMES.FIRSTNAME]}
											onChangeCb={(e) => { setFieldValue(FORM_FIELDS_NAMES.FIRSTNAME, e.target.value) }}
											onBlurCb={handleBlur}
											disabled={isFormSubmitted.patientDetails}
										/>
									</div>

									<div className="Patient-details-container">
										<Input
											placeholder="Enter name"
											label="Middle Name"
											name={FORM_FIELDS_NAMES.MIDDLENAME}
											value={values[FORM_FIELDS_NAMES.MIDDLENAME]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.MIDDLENAME, e.target.value);
											}}
											onBlurCb={handleBlur}
											disabled={isFormSubmitted.patientDetails}
										/>
									</div>

									<div className="Patient-details-container">
										<Input
											placeholder="Enter name"
											label="Last Name"
											isRequired="true"
											asteriskPosition="end"
											name={FORM_FIELDS_NAMES.LASTNAME}
											value={values[FORM_FIELDS_NAMES.LASTNAME]}
											onChangeCb={(e) => {
												setFieldValue(FORM_FIELDS_NAMES.LASTNAME, e.target.value);
											}}
											onBlurCb={handleBlur}
											disabled={isFormSubmitted.patientDetails}
										/>
									</div>

									<div className="Patient-details-container">
										<DatePicker
											label="Date Of Birth"
											placeholder="Choose Date"
											isRequired={true}
											value={values[FORM_FIELDS_NAMES.DOB]}
											name={FORM_FIELDS_NAMES.DOB}
											maxDate={new Date()}
											onChangeCb={(date) => {
												setFieldValue(FORM_FIELDS_NAMES.DOB, date);
											}}
											disabled={isFormSubmitted.patientDetails || isUpdate ? true : false}
										/>
									</div>

									<div className="Patient-details-container">
										<SelectDropdown
											label="Gender"
											isRequired={true}
											placeholder="Select"
											name={FORM_FIELDS_NAMES.GENDER}
											value={values[FORM_FIELDS_NAMES.GENDER]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES.GENDER, val);
											}}
											options={[
												{ label: 'Male', value: 'male' },
												{ label: 'Female', value: 'female' },
												{ label: 'Other', value: 'other' },
											]}
											disabled={isFormSubmitted.patientDetails || isUpdate ? true : false}
										/>
									</div>

									<div className="Patient-details-container">
										<SelectDropdown
											placeholder="Select"
											label="Marital Status"
											isRequired={true}
											name={FORM_FIELDS_NAMES.MARITAL_STATUS}
											value={values[FORM_FIELDS_NAMES.MARITAL_STATUS]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES.MARITAL_STATUS, val);
											}}
											options={[
												{ label: 'Married', value: 'married' },
												{ label: 'Single', value: 'single' },
												{ label: 'Divorced', value: 'divorced' },
												{ label: 'Widowed', value: 'widowed' },
											]}
											disabled={isFormSubmitted.patientDetails}
										/>
									</div>

									<div className="Patient-details-container">
										<div>
											<div className="flex items-center">
												<Label
													fontWeight="bold"
													isRequired={isAadharProvided}
													asteriskPosition="end"
												>
													Aadhar Number
												</Label>
												<div className="flex pl-2">
													<Checkbox
														name={FORM_FIELDS_NAMES.IS_PATIENT_AADHAR}
														checked={isAadharProvided === false}
														onChangeCb={() => {
															setIsGuardianDetailsModalOpen(true);
														}}
													/>
													<div className="pl-2 text-gray-500">
														<Label fontWeight="normal">Aadhar Not Provided </Label>
													</div>
												</div>
											</div>
											<div className="flex-1">
												<Input
													placeholder="1234-5678-9874"
													label=""
													asteriskPosition="end"
													name={FORM_FIELDS_NAMES.AADHAR_NUMBER}
													value={values[FORM_FIELDS_NAMES.AADHAR_NUMBER]}
													onChangeCb={(e) => {
														const formattedValue = General.formateAadhar(e.target.value);
														setFieldValue(FORM_FIELDS_NAMES.AADHAR_NUMBER, formattedValue);
													}}
													onBlurCb={(e) => {
														handleBlur(e);
														if (isAadharProvided && e.target.value) {
															const numberWithoutHyphens = General.numberWithoutHyphens(
																e.target.value
															);
															const numberInNumberFormat = Number(numberWithoutHyphens);
															dispatch(validateAadhaar(numberInNumberFormat));
														}
													}}
													disabled={isFormSubmitted.patientDetails || isUpdate ? true : false}
												/>
											</div>
										</div>
									</div>

									<div className="Patient-details-container">
										<SelectDropdown
											placeholder="Select"
											label="Time Zone"
											name={FORM_FIELDS_NAMES.TIMEZONE}
											value={values[FORM_FIELDS_NAMES.TIMEZONE]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES.TIMEZONE, val);
											}}
											options={TIMEZONE_OPTIONS}
											disabled={isFormSubmitted.patientDetails}
										/>
									</div>

									<div className="Patient-details-container">
										<SelectDropdown
											placeholder="Select"
											label="Language"
											name={FORM_FIELDS_NAMES.LANGUAGE}
											value={values[FORM_FIELDS_NAMES.LANGUAGE]}
											onChangeCb={(val) => {
												setFieldValue(FORM_FIELDS_NAMES.LANGUAGE, val);
											}}
											options={LANGUAGE_OPTIONS}
											disabled={isFormSubmitted.patientDetails}
										/>
									</div>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
			<div className="pt-4">
				<Label asteriskPosition="start" isRequired={true}>Indicates mandatory fields</Label>
			</div>

			{
				<GuardianDetailsModal
					open={isGuardianDetailsModalOpen}
					close={() => setIsGuardianDetailsModalOpen(false)}
				/>
			}
			{
				<ImportFromAadhaar
				open={isImportUsingAadhaarModalOpen}
					close={() => setIsImportUsingAadhaarModalOpen(false)}
				/>
			}
			{ openValidateOtpModal ? 
				<ValidateOTPModal
					open={openValidateOtpModal}
					close={() =>  {
                    dispatch(setOpenValidateOtpModal(false))}
					}
				/> : null
			}
		</>
	);
});

export default PatientDetails;
