import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label';
import Heading from '../../../../components/common-components/heading/Heading';
import { HEADING } from '../../../../components/common-components/heading/Constants';
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants';
import Table from '../../../../containers/Table/Index';
import Button from '../../../../components/common-components/button/Button';
import DiagnosesModal from './DiagnosesModal';
import { MET_COLORS } from '../../../../libs/constant';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import ButtonDropdown from '../../../../components/common-components/buttonDropdown';
import {
	componentKey as PatientDiagnosesComponentKey,
	setDiagnosesData,
	setDiagnosesId,
	setIsDiagnosesModalOpen,
	setIsNewDiagnosesAdd,
	setDiagnosesStatus
} from '../Diagnoses/PatientDiagnosesSlice';
import { componentKey as PatientDashboardComponentKey, setIsOpenDeletePopup } from '../PatientDashboardSlice';
import { deletePatientDiagnoses, getAllPatientDiagnosesDetails } from '../Diagnoses/PatientDiagnosesSaga';
import General from '../../../../libs/utility/General';
import MetPopOver from '../../../../components/common-components/popoverComponent/MetPopOver';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';

function Diagnoses() {
	const dispatch = useDispatch();
	const { isDiagnosesModalOpen, allDiagnosesData, diagnosesStatus, currentDiagnosesId } = useSelector((state) => state[PatientDiagnosesComponentKey]);

	const { currentPatientId, isOpenDeletePopup } = useSelector((state) => state[PatientDashboardComponentKey]);

	const handleDiagnosesModal = () => {
		dispatch(setIsNewDiagnosesAdd(true));
		dispatch(setDiagnosesId(''));
		dispatch(setDiagnosesData({}));
		dispatch(setIsDiagnosesModalOpen(true));
	};

	const handleEditClick = (diagnose) => {
		dispatch(setIsNewDiagnosesAdd(false));
		dispatch(setDiagnosesId(diagnose.uuid));
		dispatch(setIsDiagnosesModalOpen(true));
		dispatch(setDiagnosesData(diagnose));
	};

	const handleDeleteClick = (diagnosesId) => {
		dispatch(deletePatientDiagnoses({ patientId: currentPatientId, diagnosesId }));
	};

	useEffect(() => {
		if (!currentPatientId) return;
		dispatch(getAllPatientDiagnosesDetails({ patientId: currentPatientId, page: 1, limit: 10 }));
	}, [currentPatientId, dispatch]);

	const coloumns = [
		{
			field: 'number',
			label: <Label fontWeight="font-bold">No</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.index + 1}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'diagnosis',
			label: <Label fontWeight="font-bold">Diagnosis</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.diagnosesName}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'type',
			label: <Label fontWeight="font-bold">Type</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.type}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},

		{
			field: 'onsetDate',
			label: <Label fontWeight="font-bold">Onset Date</Label>,
			renderLogic: (row) => {
				const formattedonsetDate = General.getformattedDate(row.onsetDate)
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{formattedonsetDate}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'recordedDate',
			label: <Label fontWeight="font-bold">Recorded date</Label>,
			renderLogic: (row) => {
				const formattedRecorderDate = General.getformattedDate(row.createdAt)
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{formattedRecorderDate}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'status',
			label: <Label fontWeight="font-bold">Status</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color={row.status === 'Active' ? 'green-500' : 'red-500'}
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.status}
								</Heading>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'note',
			label: <Label fontWeight="font-bold">Note</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<MetPopOver>{row.note}</MetPopOver>
							</div>
						</div>
					</>
				);
			},
		},
		{
			field: 'action',
			label: <Label fontWeight="font-bold">Action</Label>,
			renderLogic: (row) => {
				return (
					<>
						<div className="flex items-center">
							<div>
								<Heading
									type={HEADING.H1}
									color="gray-500"
									fontSize={'md'}
									customClasses="cursor-pointer"
								>
									{row.action}
								</Heading>
							</div>
							<ButtonDropdown
								customBtnClass="p-0"
								endIcon="moreVertIcon"
								options={[
									{ label: 'Edit', value: null, onChangeCb: () => handleEditClick(row) },
									{
										label: 'Delete', value: null, onChangeCb: () => {
											dispatch(setIsOpenDeletePopup(true))
											dispatch(setDiagnosesId(row.uuid));
										}
									},
								]}
								selectCb={(option) => {
									option?.onChangeCb();
								}}
							/>
						</div>
					</>
				);
			},
		},
	];

	return (
		<>
			<div className="flex items-center justify-between p-2 px-4">
				<Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>
					Diagnoses
				</Heading>
				<div className="flex column-gap-10px">
					<SelectDropdown placeholder="Select Status"
						value={diagnosesStatus}
						onChangeCb={(val) => {
							dispatch(setDiagnosesStatus(val));
							dispatch(getAllPatientDiagnosesDetails({ patientId: currentPatientId, diagnosesStatus: val?.value }))
						}}
						options={[
							{ label: 'All', value: 'All' },
							{ label: 'Active', value: 'Active' },
							{ label: 'Historical', value: 'Historical' },
						]}
					/>
					<Button
						onClickCb={handleDiagnosesModal}
						variant={BUTTON_VARIANTS.OUTLINED}
						startIcon={{ icon: 'plusVectorIcon', color: MET_COLORS.PRIMARY }}
					>
						Add Diagnoses
					</Button>
				</div>
			</div>
			<div className="p-4">
				<Table
					isPagination={false}
					coloumns={coloumns}
					rows={allDiagnosesData}
				/>
			</div>
			{isDiagnosesModalOpen ? (
				<DiagnosesModal open={isDiagnosesModalOpen} close={() => dispatch(setIsDiagnosesModalOpen(false))} />
			) : null}

			{isOpenDeletePopup ? <DeletePopup
				open={isOpenDeletePopup}
				okButton="Delete"
				cancelButton='Cancel'
				confirmationMessage="Delete"
				onClickCancel={() => { dispatch(setIsOpenDeletePopup(false)) }}
				onClickOk={()=>handleDeleteClick(currentDiagnosesId)}
			/> : null}

		</>
	);
}

export default Diagnoses;
