import React, { useEffect, useMemo, useRef } from 'react'
import ModalComponent from '../../../../components/common-components/modal/ModalComponent'
import FaceSheetCard from '../../../../pages/Patients/PatientDashboard/Dashboard/FaceSheet/FaceSheetCard'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Table from '../../../../containers/Table/Index'
import { useDispatch, useSelector } from 'react-redux'
import { componentKey } from './GenrateSuperBillSlice';
import General from '../../../../libs/utility/General'
import { getProviderGroupList, getSuperBill } from './GenrateSuperBillSaga'
import ReactToPrint from 'react-to-print'
import GstTypeAndValues from './GstTypeAndValues'
import { useSearchParams } from 'react-router-dom'

function BillingPrintModal({ open, close, rowData }) {
    const { patientDetails, providerGrouplist, billingPrintData, billData } = useSelector(state => state[componentKey]);
    const dispatch = useDispatch()
    const [params, setParams] = useSearchParams()

    const billid = rowData ? rowData.uuid : params.get("billid");

    useEffect(() => {
        dispatch(getProviderGroupList())
        if (billid) {
            dispatch(getSuperBill(billid))
        }

    }, [billid, dispatch])

    const PROVIDER_INFORMATION = useMemo(() => {
        const { renderingProvider } = billData || {};
        const { firstName, lastName, email, phoneNumber } = renderingProvider || {};
        return {
            Name: `${firstName} ${lastName}`,
            Email: email ? email : "-",
            'Office Phone': phoneNumber ? phoneNumber : "-",
        };
    }, [billData]);


    const PATIENT_DETAILS_INFO = useMemo(() => {
        const { patient: billingPrintPatient } = billingPrintData || {};
        const { patient: billPatientdata } = billData || {};
        const { lastName: billPatientdataLastName, firstName: patientDetailsFirstName, dob: patientDetailsDob, mobileNumber: patientDetailsMobileNumber, email: patientDetailsEmail } = billPatientdata || {}
        const { lastName: billingPrintDataLastName, firstName: billingPrintDataFirstName, dob: billingPrintDataDob, mobileNumber: billingPrintDataMobileNumber, email: billingPrintDataEmail } = billingPrintPatient || {}

        const firstName = rowData ? `${billingPrintDataFirstName}` : `${patientDetailsFirstName}`
        const lastName = rowData ? `${billingPrintDataLastName}` : `${billPatientdataLastName}`
        const dob = rowData ? `${billingPrintDataDob}` : `${patientDetailsDob}`
        const mobileNumber = rowData ? `${billingPrintDataMobileNumber !== null ? billingPrintDataMobileNumber : "-"}` : `${patientDetailsMobileNumber !== null ? patientDetailsMobileNumber : "-"}`
        const email = rowData ? `${billingPrintDataEmail !== null ? billingPrintDataEmail : "-"}` : `${patientDetailsEmail !== null ? patientDetailsEmail : "-"}`

        const formattedDate = General.getformattedDate(dob)
        const { patientRelationshipToInsured } = patientDetails.Insurance?.[0] || []
        return {
            'Patient Name': `${firstName}  ${lastName}`,
            DOB: formattedDate,
            'Phone Number': mobileNumber,
            Email: email,
            Insurance: patientRelationshipToInsured,
        };
    }, [billingPrintData, billData, rowData, patientDetails.Insurance]);


    const PRACTICE_INFORMATION = useMemo(() => {

        const { groupName, email, phone } = providerGrouplist || {};
        return {
            'Location Name': groupName !== null ? groupName : "-",
            Email: email !== null ? email : "-",
            'Office Phone': phone !== null ? phone : "-",
        };
    }, [providerGrouplist]);


    const { billing_procedural_codes: billprintingCodes } = billingPrintData || {}
    const { billing_procedural_codes: billDataCodes } = billData || {}
    const { mapping_billing_payment } = billData || {}

    const { tests: billingPrintDataTests } = billingPrintData
    const { tests: billDataTests } = billData

    const method = mapping_billing_payment?.length ? mapping_billing_payment[0].patientPayment.method : "-"
    const paymentDate = mapping_billing_payment?.length ? mapping_billing_payment[0].patientPayment.paymentDate : "-"
    const formattedPaymentDate = General.getformattedDate(paymentDate)

    const calculations = useMemo(() => {
        const totalCharges = rowData ? General.getAllSum(billprintingCodes, 'charges') : General.getAllSum(billDataCodes, 'charges')
        const totalVitalTestChages = rowData ? General.getAllSum(billingPrintDataTests, 'charge') : General.getAllSum(billDataTests, 'charge')
        const totalDiscount = rowData ? General.getAllSum(billprintingCodes, 'discountInPercentage') : General.getAllSum(billDataCodes, 'discountInPercentage')
        const totalNetamount = rowData ? General.getAllSum(billprintingCodes, 'netAmount') : General.getAllSum(billDataCodes, 'netAmount')
        const grandTotal = totalNetamount + totalVitalTestChages
        return { totalVitalTestChages, totalDiscount, totalNetamount, grandTotal, totalCharges };

    }, [billDataCodes, billDataTests, billingPrintDataTests, billprintingCodes, rowData])

    const { totalVitalTestChages, totalDiscount, totalNetamount, grandTotal, totalCharges } = calculations || {}

    const coloumns = [
        {
            field: "billingCode",
            label: <Label fontWeight='md'>Billing Code</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='met-primary' fontSize={"md"} customClasses="cursor-pointer">{`${row.proceduralCode}  ${row.proceduralCodeDescription}`}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "quantity",
            label: <Label fontWeight='md'>Quantity</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.quantity}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "charges",
            label: <Label fontWeight='md'>Charges (₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.charges}</Heading>
                        </div>
                    </div>
                </>
            },
        },


        {
            field: "disc",
            label: <Label fontWeight='md'>Disc(-%)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.discountInPercentage}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "net",
            label: <Label fontWeight='md'>Net(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.netAmount}</Heading>
                        </div>
                    </div>
                </>
            },
        },

    ]

    const SummaryChargescoloumns = [
        {
            field: "totalBill",
            label: <Label fontWeight='md'>Total(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {grandTotal}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "discountInPercentage",
            label: <Label fontWeight='md'>Disc(-%)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.discountInPercentage}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "netCharges",
            label: <Label fontWeight='md'>Net Charges(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.netCharges}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "insuranceAmount",
            label: <Label fontWeight='md'>Insurance amount to be submit (-₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.insuranceAmount}</Heading>
                        </div>
                    </div>
                </>
            },
        },

        {
            field: "coPayAmount",
            label: <Label fontWeight='md'>Co-Pay(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {row.coPayAmount}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "totalBalance",
            label: <Label fontWeight='md'>Total Balance(₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.totalBalance}</Heading>
                    </div>
                </>
            },
        },

    ]

    const deviceTestcolumns = [
        {
            field: "deviceName",
            label: <Label fontWeight='md'>Device Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row.deviceName}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "testName",
            label: <Label fontWeight='md'>Test Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row.testName}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "charge",
            label: <Label fontWeight='md'>Charges (₹)</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center justify-between'>
                        <div>
                            <Heading type={HEADING.H1} color='' fontSize={"md"} customClasses="cursor-pointer">{`${row?.charge?.toFixed(2)}`}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        }
    ]



    const printingRef = useRef()
    return (
        <ModalComponent open={open} close={close}
            customClasses='w-[70%]'
            title="Superbill Receipt"
            footerButton={
                <ReactToPrint
                    trigger={() => <button className="text-met-primary bg-met-secondary border border-met-primary p-2 px-4 rounded-md">Print</button>}
                    content={() => printingRef.current}
                />
            }
        >
            <div ref={printingRef}>
                <div className='flex justify-between'>
                    <div>
                        <FaceSheetCard paddingTopClass={0} customClassName="w-[400px]">
                            <div className='text-met-primary font-bold'>PROVIDER INFORMATION</div>
                            {Object.keys(PROVIDER_INFORMATION).map((key) => (
                                <div key={key} className="flex mb-2 mt-3">
                                    <div className="   text-met-light-grey  font-semibold">{key}</div>
                                    <div className='text-gray-500 font-semibold'>:</div>
                                    <div className="ml-2 text-rgba-1a1a1acc font-semibold">
                                        {PROVIDER_INFORMATION[key] ? PROVIDER_INFORMATION[key] : '-'}
                                    </div>
                                </div>
                            ))}
                        </FaceSheetCard>
                    </div>
                    <div>
                        <FaceSheetCard paddingTopClass={0} customClassName="w-[400px]">
                            <div className='text-met-primary font-bold'>PRATICE INFORMATION</div>
                            {Object.keys(PRACTICE_INFORMATION).map((key) => (
                                <div key={key} className="flex mb-2 mt-3">
                                    <div className="   text-met-light-grey  font-semibold">{key}</div>
                                    <div className='text-gray-500 font-semibold'>:</div>
                                    <div className="ml-2 text-rgba-1a1a1acc font-semibold">
                                        {PRACTICE_INFORMATION[key] ? PRACTICE_INFORMATION[key] : '-'}
                                    </div>
                                </div>
                            ))}
                        </FaceSheetCard>
                    </div>
                </div>
                <div className='mt-4'>
                    <FaceSheetCard paddingTopClass={0} customClassName="h-[150px]">
                        <div className='text-met-primary font-bold'>PATIENT INFORMATION</div>
                        <div className="flex flex-wrap">
                            {Object.keys(PATIENT_DETAILS_INFO)?.map((key, index) => (
                                <div key={key} className='w-1/3'>
                                    <div className="flex mt-3">
                                        <div className="w-1/3 text-met-light-grey  font-semibold">{key}</div>
                                        <div className="w-2/3 text-rgba-1a1a1acc  font-semibold">
                                            {PATIENT_DETAILS_INFO[key] ? PATIENT_DETAILS_INFO[key] : '-'}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </FaceSheetCard>
                </div>
                <div className='mt-4'>
                    <FaceSheetCard>

                        <div className='text-met-primary font-bold mt-3'>Vitals Test</div>
                        <div className='mt-3'>
                            <Table isPagination={false} coloumns={deviceTestcolumns} rows={rowData ? billingPrintData?.tests : billData?.tests} />
                        </div>
                        <div className='mt-4 w-[87%] flex justify-between'>
                            <div className='font-bold ml-6  flex-1 text-rgba-1a1a1acc'>Total</div>
                            <div className='flex  justify-between mr-[100px]'>
                                <div className=' text-met-primary font-bold'>{totalVitalTestChages ? totalVitalTestChages?.toFixed(2) : 0}</div>
                            </div>
                        </div>
                    </FaceSheetCard>

                </div>

                <div className='mt-4'>
                    <FaceSheetCard>

                        <div className='text-met-primary font-bold mt-3'>ITEMS</div>
                        <div className='mt-3'>
                            <Table isPagination={false} coloumns={coloumns} rows={rowData ? billingPrintData?.billing_procedural_codes : billData?.billing_procedural_codes} />
                        </div>
                        <div className='mt-4 flex justify-between'>
                            <div className='font-bold ml-6  flex-1 text-rgba-1a1a1acc'>Total</div>
                            <div className='w-1/3 flex  justify-between mr-[100px]'>
                                <div className=' text-met-primary font-bold'>{totalCharges?.toFixed(2)}</div>
                                <div className=' text-met-primary font-bold'>{totalDiscount}</div>
                                <div className=' text-met-primary font-bold'>{totalNetamount?.toFixed(2)}</div>
                            </div>
                        </div>
                    </FaceSheetCard>

                </div>
                <div className='mt-4'>
                    <FaceSheetCard paddingTopClass={0}>
                        <div className=' flex justify-between'>
                            <div className='font-bold ml-6  flex-1 text-rgba-1a1a1acc'>Grand Total</div>
                            <div className='flex justify-between w-[50%]'>
                                <div className='text-met-primary font-bold'>{totalNetamount} + {totalVitalTestChages}</div>
                                <div className='text-met-primary font-bold'>=</div>
                                <div className=' text-met-primary font-bold'>{grandTotal}</div>
                            </div>
                        </div>
                    </FaceSheetCard>
                </div>
                <div className='mt-4'>
                    <FaceSheetCard>
                        <div className='text-met-primary font-bold'>Summary Of Charges</div>
                        <div className='mt-3'>
                            <Table isPagination={false} coloumns={SummaryChargescoloumns} rows={rowData ? [billingPrintData] : [billData]} />
                        </div>
                    </FaceSheetCard>

                </div>
                {billingPrintData?.gstType || billData?.gstType ? <div>
                    <div className='flex justify-between mt-6'>
                        <div className='w-1/4 flex'>
                            <div className='flex-1 text-rgba-1a1a1acc  fs-18 font-semibold'>GST Number</div>
                            <div className='text-met-primary fs-18 ml-6 font-semibold'>{rowData ? billingPrintData?.gstNumber : billData?.gstNumber}</div>
                            <hr className='border border-solid' />
                        </div>
                        <div className='flex flex-col w-1/4'>
                            <div className='flex mt-2 mr-3'>
                                <div className='flex-1 2 text-rgba-1a1a1acc  fs-18 font-semibold'>Sub Total</div>
                                <div className='text-met-primary fs-18 ml-6 font-semibold'>{`${rowData ? General.calculateNetTotal(billingPrintData?.totalAmountPaid, billingPrintData?.gstPercentage) : General.calculateNetTotal(billData?.totalAmountPaid, billData?.gstPercentage)}`}

                                </div>
                                <hr className='border border-solid' />
                            </div>
                            <div>
                                <GstTypeAndValues
                                    gstRate={rowData ? billingPrintData?.gstPercentage : billData.gstPercentage}
                                    gstType={rowData ? billingPrintData?.gstType : billData?.gstType}
                                    amount={rowData ? General.calculateNetTotal(billingPrintData?.totalAmountPaid, billingPrintData?.gstPercentage) : General.calculateNetTotal(billData?.totalAmountPaid, billData?.gstPercentage)} />
                            </div>
                            <hr className='border border-solid' />
                            <div className='flex mt-2 mr-3'>
                                <div className='flex-1 2 text-rgba-1a1a1acc  fs-18 font-semibold'>Total</div>
                                <div className='text-met-primary fs-18 ml-6 font-semibold'>{rowData ? billingPrintData?.totalAmountPaid : billData.totalAmountPaid?.toFixed(2)}</div>
                            </div>
                        </div>
                    </div>
                </div> : null}

                <div className='flex justify-start mt-4'>
                    <div className='flex flex-col w-1/3  bg-met-secondary p-4'>
                        <div className='flex mt-2'>
                            <div className='flex-1 fs-18 2 text-rgba-1a1a1acc font-semibold'>Amount Paid</div>
                            <div className='text-met-primary fs-18 font-semibold'>{rowData ? billingPrintData?.totalAmountPaid : billData.totalAmountPaid?.toFixed(2)}</div>
                        </div>
                        <div className='flex mt-2'>
                            <div className='flex-1 fs-18 2 text-rgba-1a1a1acc font-semibold'>Payment Method</div>
                            <div className='text-met-primary fs-18 font-semibold'>{method}</div>
                        </div>
                        <div className='flex mt-2'>
                            <div className='flex-1 fs-18 2 text-rgba-1a1a1acc font-semibold'>Payment Date</div>
                            <div className='text-met-primary fs-18 font-semibold'>{formattedPaymentDate}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalComponent>
    );
}

export default BillingPrintModal;


