import React from 'react';
import { useSelector } from 'react-redux';
import FaceSheetCard from './FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import { componentKey as PatientMedicationsComponentKey } from "../../Medications/PatientMedicationsSlice";
import General from '../../../../../libs/utility/General';

const MedicationsCard = () => {
	const navigate = useNavigate()
	const { allCurrentMedicationsData } = useSelector((state) => state[PatientMedicationsComponentKey])

	const medicationData = allCurrentMedicationsData.slice(0, 2).map((history, index) => {
		return {
			id: index + 1,
			title: history.medicineName,
			subtitle: `Start Date: ${General.getformattedDate(history.startDate)}`
		}
	})

	return (
		<FaceSheetCard
			title="MEDICATIONS"
			iconbtn={
				<Button
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass="fs-14 font-bold text-met-primary"
					style={{ height: '19px' }}
					onClickCb={() => navigate("../medications")}
				>
					View All
				</Button>
			}
			data={medicationData}
			customClassName="min-w-515"
		/>
	);
};

export default MedicationsCard;
