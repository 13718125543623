import store from "../../../store/store"
export const componentKey = 'ADD_PATIENT'

const { actions } = store.reducerManager.add({
    key: componentKey,
    addedReducers: {
        setCreatePatientData: (state, action) => {
            state.createPatientData = { ...state.createPatientData, ...action.payload }
        },
        setIsAadharProvided: (state, action) => {
            state.isAadharProvided = action.payload;
        },
        setProviderGroupLocation: (state, action) => {
            state.providerGroupLocation = action.payload;
        },
        setEmergencyContact: (state, action) => {
            state.emergencyContact = action.payload;
        },
        setInsurance: (state, action) => {
            state.emergencyContact = action.payload;
        },
        setPreferencesOptions: (state, action) => {
            const { name, data = [] } = action.payload
            switch (name) {
                case "pharmacy":
                    state.pharmacies = data
                    break;
                case "lab":
                    state.labs = data
                    break;
                case "radiology":
                    state.radiologies = data
                    break;
                default:
                    break;
            }
        },
        setIsOpenAddCenterModal: (state, action) => {
            state.isOpenAddCenterModal = action.payload;
        },
        setCreatePatientSuccessData: (state, action) => {
            state.createPatientSuccessData = action.payload;
        },
        setNewlyAddedPatientId: (state, action) => {
            state.newlyAddedPatientId = action.payload;
        },
        setFetchedPatientDetails: (state, action) => {
            state.fetchedPatientDetails = action.payload;
        },
        setFetchedEmergencyContactDetails: (state, action) => {
            state.fetchedEmergencyContactDetails = action.payload;
        },
        setIsFormSubmitted: (state, action) => {
            state.isFormSubmitted = { ...state.isFormSubmitted, ...action.payload }
        },
        setIsOpenAddInsuranceModal: (state, action) => {
            state.isOpenAddInsuranceModal = action.payload
        },
        setDeviceNamesList: (state, action) => {
            state.deviceNamesList = action.payload
        },
        setIsRedirectedFromDemographics: (state, action) => {
            state.isRedirectedFromDemographics = action.payload
        },
        setEditDemographicsData: (state, action) => {
            state.editDemographicsData = { ...state.editDemographicsData, ...action.payload }
        },
        setDeviceTestList: (state, action) => {
            state.deviceTestList = action.payload
        },
        setTransactionIdAndAadhaar:(state, action)=>{
            state.transactionIdAndAadhaar = action.payload
        },
        setOpenValidateOtpModal:(state, action)=>{
            state.openValidateOtpModal = action.payload
        },
        setCreatePatientFromAadhaar:(state, action)=>{
            state.createPatientFromAadhaar = action.payload
        }
    },
    initialReducerState: {
        createPatientData: {},
        isAadharProvided: true,
        providerGroupLocation: [],
        emergencyContact: [],
        insurance: [],
        pharmacies: [],
        labs: [],
        radiologies: [],
        isOpenAddCenterModal: false,
        createPatientSuccessData: {},
        newlyAddedPatientId: "",
        fetchedPatientDetails: {},
        fetchedEmergencyContactDetails: {},
        isFormSubmitted: {
            providerInformation: false,
            patientDetails: false,
            contactInformation: false,
            emergencyContact: false,
            insurance: false,
            preferences: false,
            privacyConsent: false,
        },
        isOpenAddInsuranceModal: false,
        deviceNamesList: [],
        isRedirectedFromDemographics: false,
        editDemographicsData: {},
        deviceTestList: [],
        transactionIdAndAadhaar:"",
        openValidateOtpModal : false,
        createPatientFromAadhaar:""
    }
})

export const { setCreatePatientData, setIsAadharProvided, setProviderGroupLocation, setEmergencyContact, setInsurance, setPreferencesOptions, setIsOpenAddCenterModal, setCreatePatientSuccessData, setNewlyAddedPatientId, setFetchedPatientDetails, setIsFormSubmitted, setFetchedEmergencyContactDetails, setIsOpenAddInsuranceModal, setDeviceNamesList, setIsRedirectedFromDemographics, setEditDemographicsData, setDeviceTestList, setTransactionIdAndAadhaar, setOpenValidateOtpModal,setCreatePatientFromAadhaar} = actions
