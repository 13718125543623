import React from 'react';
import './styles.scss';
import CardContainer from '../../components/common-components/Cards/Container/CardContainer';
import Heading from '../../components/common-components/heading/Heading';
import { HEADING } from '../../components/common-components/heading/Constants';
import AppointmentCard from '../../containers/SettingsTab/AppointmentCard';
import MyAccountCard from '../../containers/SettingsTab/MyAccountCard';
import ProviderGroupCard from '../../containers/SettingsTab/ProviderGroupCard';
import TemplatesCard from '../../containers/SettingsTab/TemplatesCard'
import MasterCard from '../../containers/SettingsTab/MasterCard'
import AllowAdminOnly from '../../containers/Wrappers/AllowAdminOnly';
import ShowForAllowedUsers from '../../containers/Wrappers/ShowForAllowedUsers';
const Settings = () => {
    return (
        <div className="settings-div">
            <CardContainer>
                <Heading fontSize="2xl" fontWeight="bold" type={HEADING.H1}>
                    Settings
                </Heading>
                <div className="flex justify-start mt-[16px]">
                    <ShowForAllowedUsers >
                        <AppointmentCard />
                    </ShowForAllowedUsers>
                    <MyAccountCard />
                    <AllowAdminOnly>
                        <ProviderGroupCard />
                    </AllowAdminOnly>
                    <ShowForAllowedUsers >
                        <TemplatesCard />
                    </ShowForAllowedUsers>
                    <MasterCard />
                </div>
            </CardContainer>
        </div>
    );
};

export default Settings;
0;
