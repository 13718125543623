import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import VaccineModal from './VaccineModal'
import { MET_COLORS } from '../../../../libs/constant'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import {
    componentKey as PatientVaccinesComponentKey,
    setActiveTab,
    setIsNewVaccineAdd,
    setIsOpenVaccinDeletePopup,
    setIsVaccinesModalOpen,
    setVaccineData,
    setVaccineId
} from '../Vaccines/PatientVaccinesSlice';
import { componentKey as PatientDashboardComponentKey } from '../PatientDashboardSlice';
import { deletePatientVaccines, getAllPatientVaccinesDetails } from '../Vaccines/PatientVaccinesSaga';
import General from '../../../../libs/utility/General';
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup';

function Vaccine() {
    const dispatch = useDispatch();
    const { allVaccinesData, isVaccinesModalOpen, isOpenVaccinDeletePopup, currentVaccineId } = useSelector((state) => state[PatientVaccinesComponentKey]);

    const { currentPatientId } = useSelector((state) => state[PatientDashboardComponentKey]);

    const handleVaccineModal = () => {
        dispatch(setIsNewVaccineAdd(true));
        dispatch(setVaccineId(''));
        dispatch(setVaccineData({}));
        dispatch(setIsVaccinesModalOpen(true));
        dispatch(setActiveTab('ADMINISTERED'))
    }

    const handleEditClick = (vaccine) => {
        dispatch(setIsNewVaccineAdd(false));
        dispatch(setIsVaccinesModalOpen(true));
        dispatch(setVaccineId(vaccine.uuid));
        dispatch(setVaccineData(vaccine))
        dispatch(setActiveTab(vaccine.type === 'Administered' ? 'ADMINISTERED' : 'HISTORICAL'))
    };

    const handleDeleteClick = (vaccineId) => {
        dispatch((deletePatientVaccines({ patientId: currentPatientId, vaccineId })));
    };

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getAllPatientVaccinesDetails({ patientId: currentPatientId, page: 1, limit: 10 }));
    }, [currentPatientId]);

    const coloumns = [
        {
            field: "number",
            label: <Label fontWeight="font-bold">No</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.index + 1}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "vaccineName",
            label: <Label fontWeight="font-bold">Vaccine Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex flex-col' >
                        <div className='flex items-center'>
                            <div>
                                <Heading type={HEADING.H1} color='gray-800' fontSize={"md"} customClasses="cursor-pointer">{row.name}</Heading>
                            </div>
                        </div>
                        <div className='flex items-center space-x-4'>
                            <Label color='gray-500'>Dosages <span className='text-gray-800'>{row.dose}</span></Label>
                            <Label color='gray-500'>Route <span className='text-gray-800'>{row.route}</span></Label>
                            <Label color='gray-500'>Site <span className='text-gray-800'>{row.site}</span></Label>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "administeredDate",
            label: <Label fontWeight="font-bold">Administered Date</Label>,
            renderLogic: (row) => {
                const formattedAdminsteredDate = General.getformattedDate(row.administerDate)
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{formattedAdminsteredDate}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "age",
            label: <Label fontWeight="font-bold">Age</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.age ? row.age : '-'}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "status",
            label: <Label fontWeight="font-bold">Status</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-800' fontSize={"md"} customClasses="cursor-pointer">{row.type}</Heading>
                            <Label color='gray-500'> By - {row.administeredBy}</Label>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit", value: null, onChangeCb: () => handleEditClick(row) }, {
                            label: "Delete", value: null, onChangeCb: () => {
                                dispatch(setIsOpenVaccinDeletePopup(true))
                                dispatch(setVaccineId(row.uuid));
                            }
                        }]} selectCb={(option) => {
                            option?.onChangeCb();
                        }} />
                    </div>
                </>
            },
        },
    ]

    return (
        <>
            <div className='flex items-center justify-between p-2 px-4'>
                <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>Vaccine</Heading>
                <div className='flex'>
                    <Button onClickCb={handleVaccineModal} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add Vaccine</Button>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={allVaccinesData} sorting={
                    {
                        isSortable: false,
                        onSortChangeCb: () => { }
                    }} />
            </div>
            {isVaccinesModalOpen ? <VaccineModal open={isVaccinesModalOpen} close={() => dispatch(setIsVaccinesModalOpen(false))} /> : null}
            {isOpenVaccinDeletePopup ? <DeletePopup
                open={isOpenVaccinDeletePopup}
                okButton="Delete"
                cancelButton='Cancel'
                confirmationMessage="Delete"
                onClickCancel={() => { dispatch(setIsOpenVaccinDeletePopup(false)) }}
                onClickOk={() => handleDeleteClick(currentVaccineId)}
            /> : null}
        </>
    )
}

export default Vaccine