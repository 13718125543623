import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setOpenValidateOtpModal } from '../../../pages/Patients/AddPatient/AddPatientSlice';
import { Form, Formik } from 'formik';
import Input from '../../../components/common-components/input/Input';
import Icons from '../../../components/icons/Icons';
import { BUTTON_VARIANTS } from '../../../components/common-components/button/Constants';
import { FORM_FIELDS_NAMES } from './Constants';
import Button from '../../../components/common-components/button/Button';
import ModalComponent from '../../../components/common-components/modal/ModalComponent';
import { VALIDATION_REGEX } from '../../../libs/constant';
import { getValidationSchema } from '../../../libs/formsUtils';
import { otpVerification } from '../../../pages/Patients/AddPatient/AddPatientSaga';


const fields = [
	{
		fieldName: FORM_FIELDS_NAMES.MOBILE_NUMBER,
		isRequired: true,
		regexPattern: VALIDATION_REGEX.NUMBER_REGEX,
	},
	{
		fieldName: FORM_FIELDS_NAMES.OTP,
		isRequired: true,
		regexPattern: VALIDATION_REGEX.NUMBER_REGEX,
	}
];
const validationSchema = getValidationSchema(fields);

const ValidateOTPModal = ({ open, close }) => {
	const dispatch = useDispatch();
	const { transactionIdAndAadhaar, createPatientData } = useSelector((state) => state[componentKey]);
	return (
		<Formik
			initialValues={
				{
					[FORM_FIELDS_NAMES.MOBILE_NUMBER]: '',
					[FORM_FIELDS_NAMES.OTP]: '',
				}
			}
			validationSchema={validationSchema}
			enableReinitialize
			onSubmit={(values) => {
				const payload = {
					...values,
					providerId: createPatientData.providerId,
					providerLocationId : createPatientData.providerLocationId,
					registrationDate: createPatientData.registrationDate,
					txnId:transactionIdAndAadhaar.transactionId,
					aadhar : Number(transactionIdAndAadhaar.aadhar)
				}
				dispatch(otpVerification({otpVerificationData:payload}));
				
			}}
		>
			{({ submitForm, isSubmitting, handleReset, values, handleSubmit, setFieldValue, handleBlur, ...props }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<div>
							<ModalComponent
								open={open}
								close={close}
								title={'Validate OTP'}
								footerButton={
									<Button
										type="submit"
										variant={BUTTON_VARIANTS.CONTAINED}
										customBtnClass="text-met-primary"
									>
										Submit
									</Button>
								}
								customClasses="w-[800px]"
							>
								<div className="p-2">
									<div className="flex column-gap-10px items-center pt-6">
										<div className="flex-1">
											<div>
												<Input
													label="OTP"
													placeholder="Enter OTP"
													isRequired={true}
													name={FORM_FIELDS_NAMES.OTP}
													value={values[FORM_FIELDS_NAMES.OTP]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.OTP,
															e.target.value
														);
													}}
													onBlurCb={handleBlur}
												/>
											</div>
											<div>
												<Input
													label="Mobile Number"
													placeholder="Mobile Number"
													isRequired={true}
													name={FORM_FIELDS_NAMES.MOBILE_NUMBER}
													value={values[FORM_FIELDS_NAMES.MOBILE_NUMBER]}
													onChangeCb={(e) => {
														setFieldValue(
															FORM_FIELDS_NAMES.MOBILE_NUMBER,
															e.target.value
														);
													}}
													onBlurCb={handleBlur}
												/>
											</div>
										</div>
									</div>
								</div>
							</ModalComponent>
						</div>
					</Form>
				);
			}}
		</Formik>
	);
};

export default ValidateOTPModal;
