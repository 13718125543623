import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { componentKey } from '../../routes/RoutesSlice';
import { USER_ROLE_NAMES } from './Constants';
import IsAccessAllowed from './IsAccessAllowed';

const ShowForAllowedUsers = ({ fallback, children, navigateUrl = null }) => {

    const [isAdminUser, setIsAdminUser] = useState(true)
    const { loggedInProviderUser } = useSelector(state => state[componentKey])
    const navigate = useNavigate()


    useEffect(() => {
        const allowedRoles = [
            USER_ROLE_NAMES.ADMIN_PROVIDER,
            USER_ROLE_NAMES.PROVIDER
        ]

        const currentRole = loggedInProviderUser?.roleNames || []
        const status = IsAccessAllowed(allowedRoles, currentRole)
        setIsAdminUser(status)
    }, [loggedInProviderUser?.roleNames])

    if (!isAdminUser) {
        if (fallback) {
            return fallback()
        } else if (navigateUrl) {
            return navigate(navigateUrl)
        }
        return <></>
    }

    return (
        <>{children}</>
    )
};
export default ShowForAllowedUsers;
