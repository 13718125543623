import React, { useEffect, useState } from 'react'
import Label from '../../../../components/common-components/label/Label'
import Heading from '../../../../components/common-components/heading/Heading'
import { HEADING } from '../../../../components/common-components/heading/Constants'
import ButtonDropdown from '../../../../components/common-components/buttonDropdown'
import Button from '../../../../components/common-components/button/Button'
import { BUTTON_VARIANTS } from '../../../../components/common-components/button/Constants'
import Table from '../../../../containers/Table/Index'
import { MET_COLORS } from '../../../../libs/constant'
import AddFamilyMemberModal from './AddFamilyMemberModal'
import { useDispatch, useSelector } from 'react-redux'
import { deleteFamilyMembers, getFamilyMembers } from './FamilySaga'
import { componentKey, setIsAddFamilyMemberModalOpen, setIsEditFamilyMemberOpen, setIsOpenFamilyMemberDeletePopup } from './FamilySlice'
import { componentKey as currentPatientIdkye } from '../../PatientDashboard/PatientDashboardSlice'
import General from '../../../../libs/utility/General'
import DeletePopup from '../../../../components/common-components/deletePopup/DeletePopup'
function Family() {

    const { familyMembersData, isAddFamilyMemberModalOpen, isOpenFamilyMemberDeletePopup } = useSelector((state) => state[componentKey])
    const { currentPatientId } = useSelector((state) => state[currentPatientIdkye]);

    const [rowData, setRowData] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!currentPatientId) return;
        dispatch(getFamilyMembers({ uuid: currentPatientId }))
    }, [currentPatientId, dispatch])

    const handleOpenAddFamilyMember = (row) => {
        setRowData(row)
        dispatch(setIsEditFamilyMemberOpen(false))
        dispatch(setIsAddFamilyMemberModalOpen(true))
    }

    const handleDeleteClick = (row) => {
        dispatch(deleteFamilyMembers({ uuid: rowData.uuid, patientId: currentPatientId }))
    }

    const handleEditClick = (row) => {
        setRowData(row)
        dispatch(setIsAddFamilyMemberModalOpen(true))
        dispatch(setIsEditFamilyMemberOpen(true))
    }

    const coloumns = [
        {
            field: "name",
            label: <Label fontWeight="font-bold">Name</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex flex-col' >
                        <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer"> {`${row.firstName} ${row.lastName}`}</Heading>
                    </div>
                </>
            },
        },
        {
            field: "relation",
            label: <Label fontWeight="font-bold">Relation</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">
                                {row.relationWithPatient ? General.camelToPascalWithSpaces(row.relationWithPatient) : '-'}
                            </Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "age",
            label: <Label fontWeight="font-bold">Age</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row?.age}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "contactNumber",
            label: <Label fontWeight="font-bold">Contact Number</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row?.contactNumber}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "email",
            label: <Label fontWeight="font-bold">Email</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row?.email}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "address",
            label: <Label fontWeight="font-bold">Address</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer">{row?.address}</Heading>
                        </div>
                    </div>
                </>
            },
        },
        {
            field: "action",
            label: <Label fontWeight="font-bold">Action</Label>,
            renderLogic: (row) => {
                return <>
                    <div className='flex items-center'>
                        <div>
                            <Heading type={HEADING.H1} color='gray-500' fontSize={"md"} customClasses="cursor-pointer" onClickCb={() => setRowData(row)} >{row.action}</Heading>
                        </div>
                        <ButtonDropdown customBtnClass="p-0" endIcon="moreVertIcon" options={[{ label: "Edit", value: null, onChangeCb: () => handleEditClick(row) }, {
                            label: "Delete", value: null, onChangeCb: () => {
                                dispatch(setIsOpenFamilyMemberDeletePopup(true))
                                setRowData(row)
                            }
                        }]} selectCb={(option) => {
                            option?.onChangeCb();
                        }} />
                    </div>
                </>
            },
        },
    ]
    return (
        <>
            <div className='flex items-center justify-between p-2 px-4'>
                <Heading fontSize="xl" fontWeight="bold" type={HEADING.H3}>Family</Heading>
                <div className='flex'>
                    <Button onClickCb={handleOpenAddFamilyMember} variant={BUTTON_VARIANTS.OUTLINED} startIcon={{ icon: "plusVectorIcon", color: MET_COLORS.PRIMARY }} >Add Family Member </Button>
                </div>
            </div>
            <div className='p-4'>
                <Table isPagination={false} coloumns={coloumns} rows={familyMembersData || []} />
            </div>
            {isAddFamilyMemberModalOpen && <AddFamilyMemberModal open={isAddFamilyMemberModalOpen} close={() => dispatch(setIsAddFamilyMemberModalOpen(false))} rowData={rowData} />}
            {isOpenFamilyMemberDeletePopup ? <DeletePopup
                open={isOpenFamilyMemberDeletePopup}
                okButton="Delete"
                cancelButton='Cancel'
                confirmationMessage="Delete"
                onClickCancel={() => { dispatch(setIsOpenFamilyMemberDeletePopup(false)) }}
                onClickOk={() => handleDeleteClick(rowData)}
            /> : null}
        </>
    )
}

export default Family