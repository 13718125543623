import React from 'react';
import { useSelector } from 'react-redux';
import FaceSheetCard from './FaceSheetCard';
import Button from '../../../../../components/common-components/button/Button';
import { BUTTON_VARIANTS } from '../../../../../components/common-components/button/Constants';
import { useNavigate } from 'react-router-dom';
import { componentKey as PatientHistoryComponentKey } from "../../History/PatientHistorySlice";
import General from '../../../../../libs/utility/General';

const PastSurgicalHistorycard = () => {
	const navigate = useNavigate()
	const { allSurgicalHistoryData } = useSelector((state) => state[PatientHistoryComponentKey])

	const pastSurgicalHistoryData = allSurgicalHistoryData.slice(0, 2).map((history, index) => {
		return {
			id: index + 1,
			title: history.surgeryName,
			subtitle: `Surgical Date: ${General.getformattedDate(history.surgeryDate)}`
		}
	})

	return (
		<FaceSheetCard
			title="PAST SURGICAL HISTORY"
			iconbtn={
				<Button
					variant={BUTTON_VARIANTS.TEXT}
					customBtnClass="fs-14 font-bold text-met-primary"
					style={{ height: '19px' }}
					onClickCb={() => navigate("../history/past-surgical")}
				>
					View All
				</Button>
			}
			data={pastSurgicalHistoryData}
			customClassName="min-w-515"
		/>
	);
};

export default PastSurgicalHistorycard;
