import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import { profileFieldNames, profileFieldsArray } from './constant';
import Input from '../../../../../components/common-components/input/Input';
import SelectDropdown from '../../../../../components/common-components/selectDropdown';
import { Form, Formik } from 'formik';
import { getValidationSchema } from '../../../../../libs/formsUtils';
import TextArea from '../../../../../components/common-components/textArea/TextArea';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey as ProviderKey } from './ProviderSlice';
import { getAllDepartments, getAllProviderLocation, getProviderSepcialities, saveProvidersDetails } from './ProviderProfileSaga';
import { componentKey as dataLoaderKey } from '../../../../Wrappers/Dataloader/DataloaderSlice';
import DatePicker from '../../../../../components/common-components/datePicker';
import moment from 'moment';
import Upload from '../../../../../components/common-components/upload/Upload';
import General from '../../../../../libs/utility/General';
import { MET_COLORS } from '../../../../../libs/constant';
import Icons from '../../../../../components/icons/Icons';
import { componentKey } from '../../../../../routes/RoutesSlice';

const EditProfileModal = forwardRef(function EditProfileModal(props, formRef) {

	const { providerProfile } = useSelector(state => state[ProviderKey])
	const { indianStates } = useSelector(state => state[dataLoaderKey])
	const { loggedInUser } = useSelector(state => state[componentKey])
	const { allproviderLocations } = useSelector(state => state[ProviderKey])
	const { providerSepcialities, allDepartmentsData } = useSelector(state => state[ProviderKey])

	const dispatch = useDispatch()
	const uuid = providerProfile.uuid
	useEffect(() => {
		dispatch(getAllProviderLocation())
		dispatch(getProviderSepcialities())
		dispatch(getAllDepartments())
	}, [dispatch])




	const initialValues = useMemo(() => {
		const { providerType, role, firstName, lastName, gender, phoneNumber, officeFaxNumber,
			email, dob, licenceNumber,
			bio, expertiesIn, educationWorkExperience, yearsOfExperience, nmcId, licensedStates, licenceExpireDate, languagesSpoken, workLocations, image, departmentIds, profilePicture } = providerProfile;

		const { Address } = providerProfile;
		const { city, state, addressLine1, addressLine2, country, zip } = Address?.[0] || {}

		const { provider_department_intermediate } = providerProfile
		const deptData = provider_department_intermediate?.map(item => item.departmentId)

		const { provider_speciality_intermediate } = providerProfile
		const specialitydata = provider_speciality_intermediate?.map(item => item.specialityId)

		const { provider_location_intermediate } = providerProfile
		const locationData = provider_location_intermediate.map(item => item.providerLocationId)

		return {
			[profileFieldNames.PROVIDER_TYPE]: providerType ? { label: providerType, value: providerType } : '',
			[profileFieldNames.SELECT_SPECIALITY]: specialitydata || [],
			[profileFieldNames.ROLE]: role ? { label: role, value: role } : '',
			[profileFieldNames.FIRST_NAME]: firstName || '',
			[profileFieldNames.LAST_NAME]: lastName || '',
			[profileFieldNames.GENDER]: gender ? { label: gender, value: gender } : '',
			[profileFieldNames.DATE_OF_BIRTH]: dob || '',
			[profileFieldNames.NMC_ID]: nmcId || '',
			[profileFieldNames.PROVIDER_PHONE_NUMBER]: phoneNumber || 0,
			[profileFieldNames.OFFICE_FAX_NUMBER]: officeFaxNumber || '',
			[profileFieldNames.EMAIL]: email || '',
			[profileFieldNames.LANGUAGES_SPOKEN]: languagesSpoken || [],
			[profileFieldNames.YEARS_OF_EXPRIENCE]: yearsOfExperience || 0,
			[profileFieldNames.WORK_LOCATIONS]: locationData || [],
			[profileFieldNames.ADDRESS_1]: addressLine1 || '',
			[profileFieldNames.ADDRESS_2]: addressLine2 || '',
			[profileFieldNames.CITY]: city || '',
			[profileFieldNames.STATE]: state ? { label: state, value: state } : '',
			[profileFieldNames.COUNTRY]: country || '',
			[profileFieldNames.ZIP_CODE]: zip || "",
			[profileFieldNames.LICENSED_STATE]: licensedStates || [],
			[profileFieldNames.LICENCE_NUMBER]: licenceNumber || '',
			[profileFieldNames.LICENCE_EXPIRY_DATE]: licenceExpireDate || undefined,
			[profileFieldNames.BIO]: bio || "",
			[profileFieldNames.EXPERTIESIN]: expertiesIn || "",
			[profileFieldNames.EDUCATIONWORKEXPRIENCE]: educationWorkExperience || "",
			[profileFieldNames.PROVIDER_IMAGE]: '',
			[profileFieldNames.DEPARTMENT]: deptData || [],

		};
	}, [providerProfile]);


	const dropdownOptions = [
		{
			name: 'languagesSpoken',
			options: [
				{ label: "Hindi", value: "Hindi" },
				{ label: "Bengali", value: "Bengali" },
				{ label: "English", value: "English" },
				{ label: "Telugu", value: "Telugu" },
				{ label: "Marathi", value: "Marathi" },
				{ label: "Tamil", value: "Tamil" },
				{ label: "Urdu", value: "Urdu" },
				{ label: "Gujarati", value: "Gujarati" },
				{ label: "Kannada", value: "Kannada" },
				{ label: "Odia", value: "Odia" },
				{ label: "Punjabi", value: "Punjabi" },
				{ label: "Malayalam", value: "Malayalam" }
			]
		},
		{
			name: 'role',
			options: [{ label: "Facility Admin", value: "Facility_Admin" },
			{ label: "Provider", value: "Provider" },
			{ label: "Provider Admin", value: "Provider Admin" },
			{ label: "Front Desk", value: "Front Desk" },
			{ label: "Biller", value: "Biller" },
			]
		},
		{
			name: 'providerType',
			options: [{ label: "MD", value: "MD" }, { label: "PA", value: "PA" }, { label: 'PSYD', value: 'PSYD' },
			{ label: 'LCSW', value: 'LCSW' }, { label: 'NP', value: 'NP' }
			]
		},
		{
			name: 'gender',
			options: [{ label: "Male", value: "male" }, { label: "Female", value: "female" }, { label: "Other", value: "other" }]
		},
		{
			name: 'state',
			options: indianStates
		},
		{
			name: 'licensedStates',
			options: indianStates
		},
		{
			name: 'locationIds',
			options: allproviderLocations

		},
		{
			name: 'speciality',
			options: providerSepcialities
		},
		{
			name: 'departmentIds',
			options: allDepartmentsData
		}
	];

	const getFeildOption = (fieldName) => {
		const currentField = dropdownOptions?.find(opt => opt.name === fieldName)
		return currentField?.options || []
	}

	const getFormGroupElements = (values, setFieldValue) => {
		const isProviderOrAdmin = loggedInUser.role !== "Front Desk" && loggedInUser.role !== "Biller"
		return profileFieldsArray(isProviderOrAdmin).map((field, index) => {
			const opts = getFeildOption(field.fieldName)
			const HideDropdown =
				(loggedInUser.role === "Front Desk" || loggedInUser.role === "Biller") &&
				(field.fieldName === 'providerType' || field.fieldName === 'speciality' || field.fieldName === 'licensedStates')
			const HideInput =
				(loggedInUser.role === "Front Desk" || loggedInUser.role === "Biller") &&
				(field.fieldName === 'nmcId' || field.fieldName === 'licenceNumber')
			const HideDatepicker =
				(loggedInUser.role === "Front Desk" || loggedInUser.role === "Biller") &&
				(field.fieldName === 'licenceExpireDate')
			return (
				<>
					{!HideDropdown && !HideInput && !HideDatepicker ? <div key={field.fieldName + index} className='w-1/3 pl-4 mt-6'>
						{field.type === 'dropdown' ? (
							<SelectDropdown
								isMultiSelect={field.isMultiSelect}
								placeholder={field.placeholder}
								label={field.fieldLabel}
								isRequired={field.isRequired}
								name={field.fieldName}
								value={values[field.fieldName]}
								onChangeCb={(val) => {
									const values = field.isMultiSelect ? val.map(item => item.value) : val;
									setFieldValue(field.fieldName, values);
								}}
								options={opts}
								disabled={field.fieldName === "role"}

							/>
						) : field.type === 'text' ? (
							<Input
								label={field.fieldLabel}
								isRequired={field.isRequired}
								placeholder={field.placeholder}
								name={field.fieldName}
								value={values[field.fieldName]}
								onChangeCb={(e) => {
									setFieldValue(field.fieldName, e.target.value);
								}}
								type={field.type}
								disabled={field.fieldName === "email"}
							/>
						) : field.type === 'date' ? (
							<DatePicker
								maxDate={field.fieldName === profileFieldNames.DATE_OF_BIRTH ? new Date(new Date().setDate(new Date().getDate() - 1)) : null}
								minDate={field.fieldName === profileFieldNames.LICENCE_EXPIRY_DATE ? new Date() : null}
								label={field.fieldLabel}
								placeholder={field.placeholder}
								value={moment(values[field.fieldName]).format()}
								name={field.fieldName}
								onChangeCb={(date) => {
									const isoString = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
									setFieldValue(field.fieldName, isoString)

								}}
							/>
						) : null}
					</div> : null}
				</>
			);
		});
	};

	const isProviderOrAdmin = loggedInUser.role !== "Front Desk" && loggedInUser.role !== "Biller"
	const validationSchema = getValidationSchema(profileFieldsArray(isProviderOrAdmin));

	return (
		<div className="max-h-[100%] overflow-y-auto scrollbar-h-2 px-2 pb-[10px] text-rgba-1a1a1acc">
			<Formik
				innerRef={formRef}
				initialValues={initialValues}
				validationSchema={validationSchema}
				enableReinitialize
				onSubmit={(values) => {
					values.nmcId = values.nmcId.toString()
					values.gender = values.gender.value
					values.providerType = values.providerType.value
					values.state = values.state.value
					values.role = values.role.value
					values.zip = values.zip.toString(),
						values.yearsOfExperience = General.sanitizeNumbers(values?.yearsOfExperience)
					values.officeFaxNumber = values.officeFaxNumber.toString()
					dispatch(saveProvidersDetails({ values, uuid }));
				}}
			>
				{({
					submitForm,
					isSubmitting,
					handleReset,
					values,
					handleSubmit,
					setFieldValue,
					handleBlur,
					...props
				}) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div className="flex">
								<div>
									<Upload
										url={providerProfile.profilePicture}
										customClass='w-[130px] h-[130px]'
										subTitle='Upload'
										icon={<Icons iconName="fileUpload" color={MET_COLORS.DARK_GRAY} />}
										onChangeCb={async (file) => {
											const mimeType = file?.type || ""
											const base64String = await General.fileToBase64(file)
											const base64 = `data:${mimeType};base64,${base64String}`
											setFieldValue(profileFieldNames.PROVIDER_IMAGE, base64)
										}}
									/>
								</div>
								<div className="flex items-center flex-wrap">{getFormGroupElements(values, setFieldValue)}</div>
							</div>
							{!(loggedInUser.role === "Front Desk" || loggedInUser.role === "Biller") ?
								<>
									<p className="text-[18px] font-medium text-met-primary mt-6">Basic Account Profile Data</p>
									<div className="mt-6">
										<TextArea
											label="Provider Bio"
											placeholder="Enter Bio..."
											customClasses="mt-2"
											name={profileFieldNames.BIO}
											value={values[profileFieldNames.BIO]}
											onChangeCb={(e) => { setFieldValue(profileFieldNames.BIO, e.target.value) }}
										/>
									</div>
									<div className="mt-6">
										<TextArea
											label="Expertise In"
											placeholder="Enter Expertise"
											customClasses="mt-2"
											name={profileFieldNames.EXPERTIESIN}
											value={values[profileFieldNames.EXPERTIESIN]}
											onChangeCb={(e) => { setFieldValue(profileFieldNames.EXPERTIESIN, e.target.value) }}
										/>
									</div>
									<div className="mt-6">
										<TextArea
											label="Education, Work Experience"
											placeholder="Enter Education, Work Experience"
											customClasses="mt-2"
											name={profileFieldNames.EDUCATIONWORKEXPRIENCE}
											value={values[profileFieldNames.EDUCATIONWORKEXPRIENCE]}
											onChangeCb={(e) => { setFieldValue(profileFieldNames.EDUCATIONWORKEXPRIENCE, e.target.value) }}
										/>
									</div>
								</> : null}
						</Form>
					);
				}}
			</Formik>
		</div >
	);
});

export default EditProfileModal;
