import React from 'react';

const FaceSheetCard = ({ children, title, iconbtn, data = [], textColorClass, customClassName, paddingTopClass, comingFrom }) => {

    const role = localStorage.getItem('role')
	const defaultChildren = (
		<ul className={`${paddingTopClass ?? 'pt-3'}`}>
			{data?.map((item, index) => (
				<li key={"face-sheet-card-" + title + item?.title + index} className="mb-4">
					<div className="flex items-center mb-1">
						<div className="h-2 w-2 bg-met-primary rounded-full mr-2"></div>
						<div className="fs-14 ">{item?.titlePrefix}</div>
						<div className={`fs-14 ml-2  ${textColorClass}`}>{item?.title}</div>
						<div className="fs-14 text-gray-500 ml-2">{item?.titleSuffix}</div>
					</div>
					<div className="text-gray-500 fs-14 ml-4">{item?.subtitle}</div>
				</li>
			))}
		</ul>
	);

    return (
        <>
            {(title === 'Unpaid Billing' || title === 'Patients' ) && role === 'Biller' &&
                <div
                    className={`bg-white bg-no-repeat bg-padding-box border border-gray-300 rounded-lg shadow-sm p-4 ${customClassName}`}
                >
                    <div className="flex flex-wrap justify-between">
                        <div className={`font-semibold ${textColorClass}`} title={title}>{title}</div>
                        <div>{iconbtn}</div>
                    </div>
                    <div className={`${paddingTopClass ?? 'pt-3'}`}>{children || defaultChildren}</div>
                </div>
            }
            {(title === 'Scheduled' || title === 'Patient Appointment' || title === 'Patients' || title === 'Test Conducted' || title === 'Unsigned Encounter' || title === 'Pending Reports') && role === 'Front Desk' &&
                <div
                    className={`bg-white bg-no-repeat bg-padding-box border border-gray-300 rounded-lg shadow-sm p-4 ${customClassName}`}
                >
                    <div className="flex flex-wrap justify-between">
                        <div className={`font-semibold ${textColorClass}`} title={title}>{title}</div>
                        <div>{iconbtn}</div>
                    </div>
                    <div className={`${paddingTopClass ?? 'pt-3'}`}>{children || defaultChildren}</div>
                </div>
            }
            { role !== 'Front Desk' && role !== 'Biller' &&
                <div
                    className={`bg-white bg-no-repeat bg-padding-box border border-gray-300 rounded-lg shadow-sm p-4 ${customClassName}`}
                >
                    <div className="flex flex-wrap justify-between">
                        <div className={`font-semibold ${textColorClass}`} title={title}>{title}</div>
                        <div>{iconbtn}</div>
                    </div>
                    <div className={`${paddingTopClass ?? 'pt-3'}`}>{children || defaultChildren}</div>
                </div>
            }
            { (role !== 'Front Desk' && (comingFrom ? role !== 'Biller' : role === 'Biller')) &&
                <div
                    className={`bg-white bg-no-repeat bg-padding-box border border-gray-300 rounded-lg shadow-sm p-4 ${customClassName}`}
    >
        <div className="flex flex-wrap justify-between">
            <div className={`font-semibold ${textColorClass}`} title={title}>{title}</div>
            <div>{iconbtn}</div>
        </div>
        <div className={`${paddingTopClass ?? 'pt-3'}`}>{children || defaultChildren}</div>
    </div>
}

        </>
    );
};

export default FaceSheetCard;
