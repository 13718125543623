import React, { useEffect, useState } from 'react';
import SelectDropdown from '../../../../components/common-components/selectDropdown';
import { getAllPatientDeviceVitalsResult, getDeviceNameList, getPatientVitalDeviceTest } from './PatientVitalsSaga';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { componentKey, setSelectedDevice, setSelectedTestName } from './PatientVitalsSlice';
import { VitalsLineChart } from './VitalsLineChart';
import moment from 'moment';
import useGarbageCollector from '../../../../libs/garbagecollector/garbageCollectorHooks';

const VitalsDevice = () => {
    useGarbageCollector(componentKey);

    const dispatch = useDispatch();
    const { patientId } = useParams();

    const [xValues, setXValues] = useState([]);
    const [yAxisValues, setYAxisValues] = useState([]);
    const [yAxisValues2, setYAxisValues2] = useState([]);

    const { deviceNamesOptionList, selectedDevice, vitalTestsOptions, selectedTestName, patientDeviceVitals } = useSelector(state => state[componentKey]);

    useEffect(() => {
        dispatch(getDeviceNameList());
    }, []);

    useEffect(() => {
        if (selectedDevice.value) {
            dispatch(getPatientVitalDeviceTest({ selectedDevice: selectedDevice.value }));
        }
    }, [selectedDevice]);

    useEffect(() => {
        if (patientId) {
            dispatch(getAllPatientDeviceVitalsResult({ patientId }));
        }
    }, [patientId]);

    useEffect(() => {
        const selectedTest = (selectedTestName?.label || "").trim();
        const selectedTestVitals = [];

        setXValues([]);

        const newDates = [];
        patientDeviceVitals.forEach(test => {
            const formattedDate = moment(test?.createdAt).format('DD-MM-YY hh:mm A');
            newDates.push(formattedDate);

            test?.testResult?.forEach(result => {
                if (result.name === selectedTest) {
                    selectedTestVitals.push(result);
                }
            });
        });
        setXValues(newDates);

        const testValueKeyMapping = {
            "MeasureBP": ["sys", "dia"],
            "TemperatureTest": ["result"],
            "BMITest": ["bmiValue"],
            "SPO2Test": ["spovalue"],
            "GlucoseTest": ["result"],
            "HBTest": ["result"],
            "FarVisionTest": ["result"],
            "NearVisionTest": ["result"],
            "HBA1CTest": ["result"],
            "SPIROTest": ["result"],
            "hsCRPTest": ["result"],
            "CRPTest": ["result"],
            "BhcgTest": ["result"],
            "CKMBTest": ["result"],
            "StethoscopeTest": ["stethoscope"],
            "ECGTest": ["ecg"],
            "DermascopeTest": ["dermascope"],
            "OtoscopeTest": ["otoscope"]
        };

        if (selectedTest in testValueKeyMapping) {
            const keys = testValueKeyMapping[selectedTest];

            if (selectedTest === "MeasureBP") {
                const systolic = selectedTestVitals?.map((item) => item[keys[0]]);
                const diastolic = selectedTestVitals?.map((item) => item[keys[1]]);

                setYAxisValues(systolic);
                setYAxisValues2(diastolic);
            } else {
                const results = selectedTestVitals?.map((item) => item[keys[0]]);
                setYAxisValues(results);
            }
        }

    }, [patientDeviceVitals, selectedTestName]);

    const isBPVital = selectedTestName?.value === "Blood Pressure" || selectedTestName?.value === "MeasureBP";

    return (
        <>
            <div className='p-5 '>
                <div className='flex space-x-4 pt-6'>
                    <div className='w-1/4 min-w-410 max-w-450'>
                        <SelectDropdown
                            placeholder='Select'
                            label='Select Device'
                            isRequired={true}
                            value={selectedDevice}
                            onChangeCb={(val) => {
                                dispatch(setSelectedDevice(val));
                            }}
                            options={deviceNamesOptionList}
                        />
                    </div>
                    {selectedDevice?.value && (
                        <div className={`w-1/4 min-w-410 max-w-450`}>
                            <SelectDropdown
                                placeholder='Select'
                                label='Select Test'
                                isRequired={true}
                                value={selectedTestName}
                                onChangeCb={(val) => {
                                    dispatch(setSelectedTestName(val));
                                }}
                                options={vitalTestsOptions}
                            />
                        </div>
                    )}
                </div>
            </div>

            
                {!yAxisValues?.length ? (
                    <div className='flex justify-center text-gray-500 font-bold mt-[60px]'>
                        No Data Found!
                    </div>
                ) : (
                    <div className="flex flex-wrap justify-between">
                        <VitalsLineChart isBPVital={isBPVital} selectedVital={selectedTestName} xValues={xValues} yAxisValues={yAxisValues} yAxisValues2={yAxisValues2} />
                    </div>
                )}
            
        </>
    );
};

export default VitalsDevice;
